import React from "react";
import "./aboutUsHeader.css";

const Header = () => {
  return (
    <header className="about-us-header">
      <h2>About Us</h2>
    </header>
  );
};

export default Header;
