import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { fetchUserProfile, getSessionUserData } from "../userSession/session"; // Import your session data retrieval function
import "./dashboardHeader.css";

const Header = () => {
  const navigate = useNavigate();
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    const sessionData = getSessionUserData();
    if (sessionData && sessionData.profile) {
      setUserData({
        name: `${sessionData.profile.first_name} ${sessionData.profile.last_name}`,
        profilePicture: sessionData.profile.profile_picture || "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png",
      });
    } else {
      console.error("Failed to retrieve user session data.");
    }
  }, []);

  const handleProfileClick = () => {
    navigate("/profile"); // Navigate to the profile page
  };

  return (
    <header className="header">
      {userData ? (
        <div
          className="profile"
          onClick={handleProfileClick}
          style={{ cursor: "pointer" }}
        >
          <span className="username">{userData.name}</span>
          <img
            src={userData.profilePicture}
            alt="Profile"
            className="profile-pic"
          />
        </div>
      ) : (
        <p>Loading...</p> // Placeholder while user data is being fetched
      )}
    </header>
  );
};

export default Header;