import BackButtonImg from "../photos/ProfileBackButton.png";

export default function ProfileNavBar(props) {
  return (
    <nav className="profile-navbar">
      <button className="back-btn" onClick={props.goBack}>
        <img src={BackButtonImg} alt="Back Button" className="back-btn-img" />
      </button>
      <h1 className="profile-title">My Profile</h1>
      <button className="save-changes-btn" onClick={props.handleEditClick}>
        {props.editProfile ? "Save Changes" : "Edit Profile"}
      </button>
    </nav>
  );
}
