import { useNavigate, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import Sidebar from "../components/sidebar";
import Header from "../components/dashboardHeader";
import "./editlistingpage.css";
import AWS from "aws-sdk";

export default function EditListingPage() {
  const navigate = useNavigate();
  const { listingId } = useParams();
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [formData, setFormData] = useState({
    street: "",
    houseNumber: "",
    zipcode: "",
    city: "",
    state: "",
    country: "",
    wifi: "",
    parking: "",
    laundry: "",
    accessibility: "",
    rent: "",
    roomType: "",
    furnished: "",
    lease: "",
    additionalNotes: "",
  });
  const [existingPictures, setExistingPictures] = useState([]);

  useEffect(() => {
    const fetchListingData = async () => {
      try {
        const response = await fetch(`https://roomiefinder.hopto.org:5000/listings/${listingId}`);
        if (response.ok) {
          const data = await response.json();
          const boolToString = (value) => (value ? "Yes" : "No");
  
          setFormData({
            street: data.roomAddress.street || "",
            houseNumber: data.roomAddress.house_number || "",
            zipcode: data.roomAddress.zip_code || "",
            city: data.roomAddress.city || "",
            state: data.roomAddress.state || "",
            country: data.roomAddress.country || "",
            wifi: boolToString(data.amenities.is_wifi),
            parking: boolToString(data.amenities.is_parking),
            laundry: boolToString(data.amenities.is_laundry),
            accessibility: boolToString(data.amenities.is_accessible),
            rent: data.roomDescription.rent || "",
            roomType: data.roomDescription.room_type || "",
            furnished: boolToString(data.roomDescription.is_furnished),
            lease: data.roomDescription.lease_length || "",
            additionalNotes: data.roomDescription.additional_notes || "",
          });
          if (data.pictures && data.pictures.length > 0) {
            setExistingPictures(data.pictures.map(picture => picture.picture_path));
          } else {
            console.log("No pictures found in the response.");
            setExistingPictures([]); 
          }
        } else {
          console.error("Failed to fetch listing data");
        }
      } catch (error) {
        console.error("Error fetching listing data:", error);
      }
    };
  
    if (listingId) {
      fetchListingData();
    }
  }, [listingId]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleFileChange = (event) => {
    setSelectedFiles(Array.from(event.target.files));
  };

  const uploadToS3 = async (file) => {
    const s3 = new AWS.S3({
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
      region: process.env.REACT_APP_AWS_REGION,
    });

    const params = {
      Bucket: "csc648s3",
      Key: `uploads/${Date.now()}-${file.name}`,
      Body: file,
      ACL: "public-read",
    };

    return new Promise((resolve, reject) => {
      s3.upload(params, (err, data) => {
        if (err) {
          reject(err);
          console.error("S3 Upload error:", err);
        } else {
          resolve(data.Location);
          console.log("Upload success:", data.Location);
        }
      });
    });
  };

  const deleteOldPictures = async () => {
    if (!Array.isArray(existingPictures) || existingPictures.length === 0) {
      console.log("No pictures to delete.");
      return;
    }

    const s3 = new AWS.S3({
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
      region: process.env.REACT_APP_AWS_REGION,
    });
    const deletePromises = existingPictures.map((url) => {
      let key = url.replace("https://csc648s3.s3.amazonaws.com/", "");

      if (!key) {
        console.error(`Invalid picture URL: ${url}`);
        return Promise.resolve(); 
      }
      key = decodeURIComponent(key);

      const params = {
        Bucket: "csc648s3",
        Key: key, 
      };

      return s3.deleteObject(params).promise()
        .then(() => console.log(`Successfully deleted picture: ${url}`))
        .catch((err) => {
          console.error(`Failed to delete picture: ${url}`, err);
        });
    });
    await Promise.all(deletePromises);
};

  

  const handleSubmit = async (e) => {
    e.preventDefault();

    const booleanize = (value) => value === "Yes";

    const listingData = {
      roomie_provider_id: 0,
      rent: parseInt(formData.rent),
      room_type: formData.roomType,
      is_furnished: booleanize(formData.furnished),
      lease_length: formData.lease,
      additional_notes: formData.additionalNotes,
      is_wifi: booleanize(formData.wifi),
      is_parking: booleanize(formData.parking),
      is_laundry: booleanize(formData.laundry),
      is_accessible: booleanize(formData.accessibility),
      street: formData.street,
      house_number: formData.houseNumber,
      zip_code: formData.zipcode,
      city: formData.city,
      state: formData.state,
      country: formData.country,
      pictures: selectedFiles.map((file) => ({
        picture_path: `/uploads/${file.name}`,
        picture_caption: file.name,
      })),
    };

    try {
      await deleteOldPictures();
      const picturePaths = await Promise.all(
        selectedFiles.map((file) => uploadToS3(file))
      );

      listingData.pictures = picturePaths.map((path, index) => ({
        picture_path: path,
        picture_caption: selectedFiles[index].name,
      }));

      const response = await fetch(`https://roomiefinder.hopto.org:5000/listings/${listingId}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(listingData),
      });

      if (response.ok) {
        alert("Listing updated successfully!");
        navigate("/providerDashboard");
      } else {
        const error = await response.text();
        alert(`Failed to update listing: ${error}`);
      }
    } catch (error) {
      console.error("Error updating listing:", error);
      alert("Failed to update listing. Please try again.");
    }
  };

  return (
    <div className="dashboard-container">
      <Sidebar userType="provider" />
      <div className="main-content-area">
        <Header />
        <div className="header-bar">
          <h2>Edit Listing</h2>
        </div>
        <form className="edit-listing-form" onSubmit={handleSubmit}>
          <div className="left-section">
            <label className="room-address">Room Address</label>
            <div className="label-input-container">
              <label htmlFor="street" className="input-label">
                Street
              </label>
              <input
                type="text"
                id="street"
                name="street"
                className="input-field"
                value={formData.street}
                onChange={handleInputChange}
              />
            </div>
            <div className="label-input-container">
              <label htmlFor="houseNumber" className="input-label">
                House Number
              </label>
              <input
                type="text"
                id="houseNumber"
                name="houseNumber"
                className="input-field"
                value={formData.houseNumber}
                onChange={handleInputChange}
              />
            </div>
            <div className="label-input-container">
              <label htmlFor="zipcode" className="input-label">
                Zip Code
              </label>
              <input
                type="text"
                id="zipcode"
                name="zipcode"
                className="input-field"
                value={formData.zipcode}
                onChange={handleInputChange}
              />
            </div>
            <div className="label-input-container">
              <label htmlFor="city" className="input-label">
                City
              </label>
              <input
                type="text"
                id="city"
                name="city"
                className="input-field"
                value={formData.city}
                onChange={handleInputChange}
              />
            </div>
            <div className="label-input-container">
              <label htmlFor="state" className="input-label">
                State
              </label>
              <input
                type="text"
                id="state"
                name="state"
                className="input-field"
                value={formData.state}
                onChange={handleInputChange}
              />
            </div>
            <div className="label-input-container">
              <label htmlFor="country" className="input-label">
                Country
              </label>
              <input
                type="text"
                id="country"
                name="country"
                className="input-field"
                value={formData.country}
                onChange={handleInputChange}
              />
            </div>
            <label className="amenities-label">Amenities</label>
            <div className="label-input-container">
              <label htmlFor="wifi" className="input-label">
                WIFI
              </label>
              <select
                className="input-field"
                name="wifi"
                value={formData.wifi}
                onChange={handleInputChange}
              >
                <option value="" disabled></option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
            </div>

            <div className="label-input-container">
              <label htmlFor="parking" className="input-label">
                Parking
              </label>
              <select
                className="input-field"
                name="parking"
                value={formData.parking}
                onChange={handleInputChange}
              >
                <option value="" disabled></option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
            </div>

            <div className="label-input-container">
              <label htmlFor="laundry" className="input-label">
                Laundry
              </label>
              <select
                className="input-field"
                name="laundry"
                value={formData.laundry}
                onChange={handleInputChange}
              >
                <option value="" disabled></option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
            </div>

            <div className="label-input-container">
              <label htmlFor="accessibility" className="input-label">
                Accessibility
              </label>
              <select
                className="input-field"
                name="accessibility"
                value={formData.accessibility}
                onChange={handleInputChange}
              >
                <option value="" disabled></option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
            </div>
          </div>
          <div className="right-section">
            <label className="room-description">Room Description</label>
            <div className="label-input-container">
              <label htmlFor="rent" className="input-label">
                Rent
              </label>
              <input
                type="number"
                id="rent"
                name="rent"
                className="input-field"
                value={formData.rent}
                onChange={handleInputChange}
              />
            </div>
            <div className="label-input-container">
              <div className="room-type">
              <label htmlFor="roomType" className="input-label">
                Room Type
              </label>
              </div>
              <select
                className="input-field"
                name="roomType"
                id="roomType"
                value={formData.roomType}
                onChange={handleInputChange}
              >
                <option value="" disabled></option>
                <option value="Private">Private</option>
                <option value="Shared">Shared</option>
              </select>
            </div>
         

            <div className="label-input-container">
            <div className="furnished">
              <label htmlFor="furnished" className="input-label">
                Furnished
              </label>
              </div>
              <select
                className="input-field"
                name="furnished"
                id="furnished"
                value={formData.furnished}
                onChange={handleInputChange}
              >
                <option value="" disabled></option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
            </div>

            <div className="label-input-container">
            <div className="lease">
              <label htmlFor="lease" className="input-label">
                Lease
              </label>
              </div>
              <select
                className="input-field"
                name="lease"
                id="lease"
                value={formData.lease}
                onChange={handleInputChange}
              >
                <option value="" disabled></option>
                <option value="Yearly">Yearly</option>
                <option value="Monthly">Monthly</option>
              </select>
            </div>

            <div className="label-input-container">
              <label htmlFor="additionalNotes" className="input-label">
                Additional Notes
              </label>
              <textarea
                className="input-field additional-notes"
                name="additionalNotes"
                id="additionalNotes"
                value={formData.additionalNotes}
                onChange={handleInputChange}
              />
            </div>
            <label className="pictures">Pictures</label>
            <input type="file" multiple className="file-input" onChange={handleFileChange} />
            <button type="submit" className="edit-listing-btn">
              Save Changes
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}