import React, { useState, useEffect } from "react";
import ChatContacts from "../components/chatcontacts.jsx";
import ProfilePicExample1 from "../photos/ProfilePicExample1.png";
import SideBar from "../components/sidebar.jsx";
import Header from "../components/dashboardHeader.jsx";
import SearchIcon from "../photos/search.png";
import ChatWindow from "../components/chatwindow";
import { getSessionUserData } from "../userSession/session";
import "./chatpage.css";
import DefaultProfilePic from "../photos/contact.jpg";

const ChatList = ({ userType }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedContact, setSelectedContact] = useState(null); // State for the selected contact
  const [contacts, setContacts] = useState([]); // State to hold contacts fetched from the DB
  const [chats, setChats] = useState([]); // State to hold chats fetched from the DB
  const [loading, setLoading] = useState(false); // Loading state for async actions

  const userSessionData = getSessionUserData();
  const userId = userSessionData ? userSessionData.userId : null;

  // Function to fetch all contacts for the logged-in user
  const fetchContacts = async () => {
    if (!userId) return;
    setLoading(true);
    console.log("Fetching contacts from the database...");
    try {
      const response = await fetch(`https://roomiefinder.hopto.org:5000/contacts/${userId}`);
      if (!response.ok) throw new Error(`Failed to fetch contacts, status: ${response.status}`);

      const data = await response.json();
      if (data.success) {
        setContacts(data.contacts); // Update contacts state
        console.log("Fetched contacts successfully:", data.contacts, "\n");
      } else {
        console.error("Failed to fetch contacts:", data.error);
      }
    } catch (error) {
      console.error("Error fetching contacts:", error);
    } finally {
      setLoading(false);
    }
  };

  // Fetch contacts on component mount or when userId changes
  useEffect(() => {
    fetchContacts();
  }, [userId]);

  const fetchChatHistory = async (userId, contactId) => {
    console.log(`Fetching chat history between ${userId} and ${contactId} ...`);
    try {
      const response = await fetch(`https://roomiefinder.hopto.org:5000/chat/${userId}/${contactId}`);
      if (!response.ok) throw new Error("Failed to fetch chats");
  
      const data = await response.json();
  
      if (data.success) {
        const formattedChats = data.chats.map((chat) => ({
          ...chat,
          sentByUser: String(chat.sender_id) === String(userId),
        }));
        setChats(formattedChats); // Update chats state with formatted chats
        console.log("Formatted chats:", formattedChats, "\n");
      } else {
        console.error("Failed to fetch chats:", data.error);
      }
    } catch (error) {
      console.error("Error fetching chats:", error);
    }
  };

  // Callback to handle contact removal after chat deletion
  const handleContactRemoved = () => {
    // Simply re-fetch the contacts to ensure the deleted contact disappears
    fetchContacts();
    // Also clear selected contact and chats to avoid showing empty data
    setSelectedContact(null);
    setChats([]);
  };

  // Filter contacts based on the search term
  const filteredContacts = contacts.filter((contact) => {
    return contact.name.toLowerCase().includes(searchTerm.toLowerCase());
  });

  console.log("Filtered Contacts:", filteredContacts, "\n");

  const handleContactClick = (contact) => {
    console.log("Contact clicked: ", contact);
    setSelectedContact(contact); // Update selected contact
    setChats([]); // Clear previous chat history before fetching new one
    console.log("Updated Selected contact:", contact, "\n");
    fetchChatHistory(userId, contact.contact_id); // Fetch chat history for the selected contact
  };

  return (
    <div className="page-container">
      <SideBar userType={userType} />
      <div className="main-content-area">
        <Header />
        <div className="chat-page">
          <div className="chat-list">
            <div className="search-bar">
              <input
                type="text"
                placeholder="Search for Users"
                className="search-input"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
            {loading ? (
              <div>Loading Contacts...</div>
            ) : (
              filteredContacts.map((contact) => (
                <div
                  key={contact.contact_id}
                  onClick={() => handleContactClick(contact)}
                >
                  <ChatContacts
                    profilePic={contact.profile_pic || DefaultProfilePic}
                    name={contact.name}
                    lastMessage={contact.last_message}
                  />
                </div>
              ))
            )}
          </div>
          {selectedContact ? (
            <ChatWindow
              contact={selectedContact}
              chats={chats}
              userId={userId}
              onContactRemoved={handleContactRemoved} // Pass the callback to ChatWindow
            />
          ) : (
            <div className="no-chat-selected">Start a conversation by selecting a contact</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ChatList;
