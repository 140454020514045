import React, { useState, useEffect, useRef } from "react";
import { io } from "socket.io-client";
import DefaultProfilePic from "../photos/contact.jpg";
import ThreeDotMenu from "./threeDotMenu";
import { useNavigate } from "react-router-dom";
import "./chatwindow.css";

const socket = io("https://roomiefinder.hopto.org:5000/");

function ChatWindow({ contact, chats, userId, onContactRemoved }) {
  const [messages, setMessages] = useState([]); 
  const [newMessage, setNewMessage] = useState("");
  const messageContainerRef = useRef(null);
  const navigate = useNavigate(); 

  // Format a single message to determine if it was sent by the user
  const formatMessage = (message) => {
    const sentByUser = String(message.sender_id) === String(userId);
    return { ...message, sentByUser };
  };

  // Update messages whenever the chats prop changes
  useEffect(() => {
    if (chats) {
      const formattedChats = chats.map(formatMessage);
      setMessages(formattedChats);
    }
  }, [chats]);

  // Scroll to the latest message when messages are updated
  useEffect(() => {
    if (messageContainerRef.current) {
      messageContainerRef.current.scrollTop =
        messageContainerRef.current.scrollHeight;
    }
  }, [messages]);

  // Handle incoming messages
  const handleIncomingMessage = (data) => {
    const formattedMessage = formatMessage(data);
    if (
      (String(data.sender_id) === String(userId) && String(data.receiver_id) === String(contact.contact_id)) ||
      (String(data.sender_id) === String(contact.contact_id) && String(data.receiver_id) === String(userId))
    ) {
      setMessages((prevMessages) => [...prevMessages, formattedMessage]);
    }
  };
  
  useEffect(() => {
    socket.on("message", handleIncomingMessage);
    return () => {
      socket.off("message", handleIncomingMessage);
    };
  }, [contact, userId]);  

  // Handle sending a message
  const handleSend = async () => {
    if (newMessage.trim()) {
      const messageData = {
        sender_id: userId,
        receiver_id: contact.contact_id,
        message: newMessage,
        created_at: new Date().toISOString(),
      };

      const formattedMessage = formatMessage(messageData);
      setMessages((prevMessages) => [...prevMessages, formattedMessage]);
      setNewMessage("");

      try {
        const response = await fetch("https://roomiefinder.hopto.org:5000/chat", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(messageData),
        });

        if (!response.ok) {
          throw new Error("Failed to save message");
        }

        console.log("Message saved successfully");

        // Emit the message via socket
        socket.emit("message", messageData, (error) => {
          if (error) {
            console.error("Error broadcasting message:", error);
          } else {
            console.log("Message broadcasted successfully");
          }
        });
      } catch (error) {
        console.error("Error sending message:", error);
      }
    }
  };

  // Handle deleting messages
  const handleDeleteChatHistory = async () => {
    try {
      const response = await fetch(`https://roomiefinder.hopto.org:5000/chat/${userId}/${contact.contact_id}`, {
        method: "DELETE",
      });
      if (!response.ok) throw new Error("Failed to delete chat history");
      
      // Clear local messages after the delete 
      setMessages([]);
      console.log("Chat history deleted");
      
      // Notify the parent to refresh contacts and remove this contact
      if (onContactRemoved) {
        onContactRemoved(contact.contact_id);
      }
    } catch (error) {
      console.error("Error deleting chat history:", error);
    }
  };

  function redirectToProviderProfile() {
    navigate(`/providerProfile/${contact.contact_id}`); 
  }

  return (
    <div className="chatWindow">
      <div className="chat-header" style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <img
            src={contact.profile_pic || DefaultProfilePic}
            alt={contact.name || "Unknown Contact"}
            className="profile-pic"
            onClick={(e) => {
              e.stopPropagation();
              redirectToProviderProfile();
            }}
          />
          <h2>{contact.name || "Unknown Contact"}</h2>
        </div>
        <ThreeDotMenu onDeleteChatHistory={handleDeleteChatHistory} />
      </div>
      <div className="messageContainer" ref={messageContainerRef}>
        {messages.map((msg, index) => (
          <div
            key={index}
            className={msg.sentByUser ? "message-sent" : "message-received"}
          >
            {msg.message}
          </div>
        ))}
      </div>
      <div className="inputContainer">
        <input
          type="text"
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleSend();
            }
          }}
          className="input"
        />
        <button className="sendButton" onClick={handleSend}>
          Send
        </button>
      </div>
    </div>
  );
}

export default ChatWindow;
