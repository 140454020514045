import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "./pages/authenticationScreen";
import Home from "./pages/homepage";
import SearchPage from "./components/searchpage";
import Profile from "./pages/profilepage";
import RoomieFinderDashboard from "./pages/roomieFinderDashboard";
import RoomieProviderDashboard from "./pages/roomProviderDashboard";
import RoomieListPage from "./pages/roomielist";
import RolePage from "./pages/rolepage";
import ChatPage from "./pages/chatpage";
import AboutUsPage from "./pages/aboutuspage";
import reportWebVitals from "./reportWebVitals";
import CreateListingPage from "./pages/createlisting";
import SingleListingPage from "./pages/singleviewlisting";
import QuizPage from "./pages/quizpage";
import ProviderProfile from "./pages/providerprofile";
import EditListingPage from "./pages/editlistingpage";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/finderDashboard" element={<RoomieFinderDashboard />} />
        <Route
          path="/providerDashboard"
          element={<RoomieProviderDashboard />}
        />
        <Route path="/list" element={<RoomieListPage />} />
        <Route path="/chatFinder" element={<ChatPage userType="finder" />} />
        <Route
          path="/chatProvider"
          element={<ChatPage userType="provider" />}
        />
        <Route path="/login" element={<Login />} />
        <Route path="/role" element={<RolePage />} />
        <Route path="/roomielist" element={<RoomieListPage />} />
        <Route path="/about" element={<AboutUsPage />} />
        <Route path="/createListing" element={<CreateListingPage />} />
        <Route path="/singleListing/:id" element={<SingleListingPage />} />
        <Route path="/quiz" element={<QuizPage />} />
        <Route path="/providerProfile/:userId" element={<ProviderProfile />} />
        <Route path="/editListing/:listingId" element={<EditListingPage />} />
        <Route path="/rolepage" element={<RolePage />} />

      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

reportWebVitals();
