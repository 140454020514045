import AWS from "aws-sdk";
import React, { useState, useEffect } from "react";
import Sidebar from "../components/sidebar"; // Import Sidebar component
import Header from "../components/dashboardHeader";
import { useNavigate } from "react-router-dom";
import { getSessionUserData } from "../userSession/session"; // Import the getSessionUserData function
import "./createlisting.css";

const CreateListingPage = ({ userType }) => {
  const navigate = useNavigate();
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [userId, setUserId] = useState(null); // State to store userId

  // Get user data on component mount
  useEffect(() => {
    const userData = getSessionUserData(); // Assuming this returns user data
    if (userData) {
      setUserId(userData.userId); // Assuming userId is part of the session data
    }
  }, []);

  const handleFileChange = (event) => {
    setSelectedFiles(Array.from(event.target.files));
  };

  const [formData, setFormData] = useState({
    street: "",
    houseNumber: "",
    zipcode: "",
    city: "",
    state: "",
    country: "",
    wifi: "",
    parking: "",
    laundry: "",
    accessibility: "",
    rent: "",
    roomType: "",
    furnished: "",
    lease: "",
    additionalNotes: "",
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const uploadToS3 = async (file) => {
    const s3 = new AWS.S3({
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
      region: process.env.REACT_APP_AWS_REGION,
    });

    const params = {
      Bucket: "csc648s3",
      Key: `uploads/${Date.now()}-${file.name}`,
      Body: file,
      ACL: "public-read",
    };

    return new Promise((resolve, reject) => {
      s3.upload(params, (err, data) => {
        if (err) {
          reject(err);
          console.error("S3 Upload error:", err);
        } else {
          resolve(data.Location); 
          console.log("Upload success:", data.Location);
        }
      });
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const booleanize = (value) => value === "Yes";

    try {
      const picturePaths = await Promise.all(
        selectedFiles.map((file) => uploadToS3(file))
      );

      console.log("Picture Paths:", picturePaths);

      const payload = {
        roomie_provider_id: userId, 
        rent: parseInt(formData.rent),
        room_type: formData.roomType,
        is_furnished: booleanize(formData.furnished),
        lease_length: formData.lease,
        additional_notes: formData.additionalNotes,
        is_wifi: booleanize(formData.wifi),
        is_parking: booleanize(formData.parking),
        is_laundry: booleanize(formData.laundry),
        is_accessible: booleanize(formData.accessibility),
        street: formData.street,
        house_number: formData.houseNumber,
        zip_code: formData.zipcode,
        city: formData.city,
        state: formData.state,
        country: formData.country,
        pictures: picturePaths.map((path, index) => ({
          picture_path: path,  
          upload_date: new Date().toISOString(),
          picture_caption: selectedFiles[index].name,
        })),
      };

      console.log("Payload:", payload);

      const response = await fetch("https://roomiefinder.hopto.org:5000/listings", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        alert("Listing created successfully!");
        navigate("/providerDashboard");
      } else {
        const error = await response.text();
        alert(`Failed to create listing: ${error}`);
      }
    } catch (error) {
      console.error("Error creating listing:", error);
      alert("Failed to create listing. Please try again.");
    }
  };

  return (
    <div className="dashboard-container">
      <Sidebar userType="provider" />
      <div className="main-content-area">
        <Header />
        <div className="header-bar">
          <h2>Create Listing</h2>
        </div>
        <form className="create-listing-form">
          <div className="left-section">
            <label className="room-address">Room Address</label>
            <div className="label-input-container">
              <label htmlFor="street" className="input-label">
                Street
              </label>
              <input
                type="text"
                id="street"
                name="street"
                className="input-field"
                value={formData.street}
                onChange={handleInputChange}
              />
            </div>
            <div className="label-input-container">
              <label htmlFor="houseNumber" className="input-label">
                House Number
              </label>
              <input
                type="text"
                id="houseNumber"
                name="houseNumber"
                className="input-field"
                value={formData.houseNumber}
                onChange={handleInputChange}
              />
            </div>
            <div className="label-input-container">
              <label htmlFor="zipcode" className="input-label">
                Zip Code
              </label>
              <input
                type="text"
                id="zipcode"
                name="zipcode"
                className="input-field"
                value={formData.zipcode}
                onChange={handleInputChange}
              />
            </div>
            <div className="label-input-container">
              <label htmlFor="city" className="input-label">
                City
              </label>
              <input
                type="text"
                id="city"
                name="city"
                className="input-field"
                value={formData.city}
                onChange={handleInputChange}
              />
            </div>
            <div className="label-input-container">
              <label htmlFor="state" className="input-label">
                State
              </label>
              <input
                type="text"
                id="state"
                name="state"
                className="input-field"
                value={formData.state}
                onChange={handleInputChange}
              />
            </div>
            <div className="label-input-container">
              <label htmlFor="country" className="input-label">
                Country
              </label>
              <input
                type="text"
                id="country"
                name="country"
                className="input-field"
                value={formData.country}
                onChange={handleInputChange}
              />
            </div>
            <label className="amenities-label">Amenities</label>
            <div className="label-input-container">
              <label htmlFor="wifi" className="input-label">
                WIFI
              </label>
              <select
                className="input-field"
                name="wifi"
                value={formData.wifi}
                onChange={handleInputChange}
              >
                <option value="" disabled selected></option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
            </div>

            <div className="label-input-container">
              <label htmlFor="parking" className="input-label">
                Parking
              </label>
              <select
                className="input-field"
                name="parking"
                value={formData.parking}
                onChange={handleInputChange}
              >
                <option value="" disabled selected></option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
            </div>

            <div className="label-input-container">
              <label htmlFor="laundry" className="input-label">
                Laundry
              </label>
              <select
                className="input-field"
                name="laundry"
                value={formData.laundry}
                onChange={handleInputChange}
              >
                <option value="" disabled selected></option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
            </div>

            <div className="label-input-container">
              <label htmlFor="accessibility" className="input-label">
                Accessibility
              </label>
              <select
                className="input-field"
                name="accessibility"
                value={formData.accessibility}
                onChange={handleInputChange}
              >
                <option value="" disabled selected></option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
            </div>
          </div>
          <div className="right-section">
            <label className="room-description">Room Description</label>
            <div className="label-input-container">
              <label htmlFor="rent" className="input-label">
                Rent
              </label>
              <input
                type="number"
                id="rent"
                name="rent"
                className="input-field"
                value={formData.rent}
                onChange={handleInputChange}
                min="0"
              />
            </div>

            <div className="label-input-container">
              <label htmlFor="roomType" className="input-label">
                Room Type
              </label>
              <select
                className="input-field"
                name="roomType"
                id="roomType"
                value={formData.roomType}
                onChange={handleInputChange}
              >
                <option value="" disabled selected></option>
                <option value="Private">Private</option>
                <option value="Shared">Shared</option>
              </select>
            </div>

            <div className="label-input-container">
              <label htmlFor="furnished" className="input-label">
                Furnished
              </label>
              <select
                className="input-field"
                name="furnished"
                id="furnished"
                value={formData.furnished}
                onChange={handleInputChange}
              >
                <option value="" disabled selected></option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
            </div>

            <div className="label-input-container">
              <label htmlFor="lease" className="input-label">
                Lease
              </label>
              <select
                className="input-field"
                name="lease"
                id="lease"
                value={formData.lease}
                onChange={handleInputChange}
              >
                <option value="" disabled selected></option>
                <option value="Yearly">Yearly</option>
                <option value="Monthly">Monthly</option>
              </select>
            </div>

            <div className="label-input-container">
              <label htmlFor="additionalNotes" className="input-label">
                Additional Notes
              </label>
              <textarea
                className="input-field additional-notes"
                name="additionalNotes"
                id="additionalNotes"
                value={formData.additionalNotes}
                onChange={handleInputChange}
              />
            </div>
            <label className="pictures">Pictures</label>
            <input
              type="file"
              multiple
              className="file-input"
              onChange={handleFileChange}
            />            
            <button
              type="submit"
              className="create-listing-btn"
              onClick={handleSubmit}
            >
              Create Listing
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateListingPage;