import React, { useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import './herosection.css';

const hooks = [
  "Compatibility is just a click away!",
  "Enter your preferences, and let us find your perfect roommate match!",
  "Find your ideal roommate and start the conversation today",
  "Looking for the right roommate? List your room and let us find someone who truly fits!"
];

function HeroSection() {
  const [currentHookIndex, setCurrentHookIndex] = useState(0);
  const [isActive, setIsActive] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const interval = setInterval(() => {
      setIsActive(false); // Hide the current hook
      setTimeout(() => {
        setCurrentHookIndex((prevIndex) => (prevIndex + 1) % hooks.length);
        setIsActive(true); // Show the next hook
      }, 500); // Time for text to hide before changing
    }, 4000); // Change every 4 seconds

    return () => clearInterval(interval); 
  }, []);

  return (
    <div className="hero-container">
      <h1>Find your perfect roommate today!</h1>
      <p className={`changing-text ${isActive ? "fade-in" : "fade-out"}`}>
        {hooks[currentHookIndex]}
      </p>
      <div className="button-container">
        <button className="hero-button left-button" onClick={() => navigate('/login')}>
          Get Started </button>
      </div>
     
    </div>
  );
}

export default HeroSection;






