import "./Question.css";

export default function Question({ label, name, value, options, onChange }) {
  return (
    <div className="question">
      <label htmlFor={name}>{label}</label>
      <select name={name} id={name} value={value} onChange={onChange}>
        {options.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>
    </div>
  );
}
