import React from "react";
import "./aboutuspage.css";
import TeamMemberCard from "../components/TeamMemberCard.jsx";
import DashboardHeader from "../components/dashboardHeader";
import AboutUsHeader from "../components/aboutUsHeader";
import Sidebar from "../components/sidebar";
import KiranProfilePic from "../photos/KiranProfilePic.png";
import AlphaProfilePic from "../photos/AlphaProfilePic.png";
import BryanProfilePic from "../photos/BryanProfilePic.png";
import HugoProfilePic from "../photos/HugoProfilePic.jpg";
import LyliaProfilePic from "../photos/LyliaProfilePic.png";
import PreciousProfilePic from "../photos/PreciousProfilePic.png";

const AboutUsPage = () => {
  const teamMembers = [
    { name: "Kiran Poudel", image: KiranProfilePic },
    { name: "Alpha Diallo", image: AlphaProfilePic },
    { name: "Bryan Khor", image: BryanProfilePic },
    { name: "Hugo Gomez", image: HugoProfilePic },
    { name: "Lylia Melahi", image: LyliaProfilePic },
    { name: "Etinosa Osagie-Amayo", image: PreciousProfilePic },
    // Add more team members here if needed
  ];

  return (
    <div className="page-container">
      <Sidebar />
      <div className="main-content-area">
        <DashboardHeader />
        <AboutUsHeader />
        {(
          <div className="cookie-banner">
            <p>
              If you see an issue please email us at{" "}
              <strong
                style={{color: "white", textDecoration: "underline" }}
              >
                helpdesk@roomiefinder.com
              </strong>{" "}
            </p>
          </div>
        )}
        <div className="team-section">
          <div className="team-grid">
            {teamMembers.map((member, index) => (
              <TeamMemberCard
                key={index}
                name={member.name}
                image={member.image}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUsPage;
