import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { findCollegesByCity } from "../helpers/collegeLocations";
import RoomProvider from "../photos/ProfilePicExample3.png";
import SideBar from "../components/sidebar.jsx";
import Header from "../components/dashboardHeader";
import { getSessionUserData } from "../userSession/session";
import "./singleviewlisting.css";

export default function SingleViewListing() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [listingData, setListingData] = useState(null);
  const [providerData, setProviderData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [nearbyColleges, setNearbyColleges] = useState([]);
  const [compatibilityScore, setCompatibilityScore] = useState(0);
  const [messageSent, setMessageSent] = useState(false); // To track if the message was sent
  const [messages, setMessages] = useState([]); // Store the list of messages

  const userSessionData = getSessionUserData();
  const senderId = userSessionData ? userSessionData.userId : null;

  useEffect(() => {
    const fetchListingData = async () => {
      try {
        const sessionData = getSessionUserData();
        if (!sessionData || !sessionData.profile) {
          throw new Error("Failed to retrieve user profile for compatibility calculation.");
        }
        const userProfile = sessionData.profile;

        const response = await fetch(`https://roomiefinder.hopto.org:5000/listings/${id}`);
        if (!response.ok) {
          throw new Error("Failed to fetch listing data");
        }
        const data = await response.json();
        setListingData(data);

        // Find nearby colleges
        const colleges = findCollegesByCity(data.roomAddress.city);
        setNearbyColleges(colleges);

        // Fetch room provider's profile info using roomie_provider_id
        const providerResponse = await fetch(
          `https://roomiefinder.hopto.org:5000/profile/${data.listing.roomie_provider_id}`
        );
        if (!providerResponse.ok) {
          throw new Error("Failed to fetch room provider data");
        }
        const providerData = await providerResponse.json();
        setProviderData(providerData.profile);

        // Calculate compatibility score
        const attributes = ["sleep_schedule", "is_smoke", "is_pets", "cleanliness_level", "social_environment"];
        let score = 0;

        attributes.forEach((attr) => {
          if (userProfile[attr] === providerData.profile[attr]) {
            score += 1;
          }
        });

        const normalizedScore = (score / attributes.length) * 5;
        setCompatibilityScore(Math.round(normalizedScore * 10) / 10); // Round to 1 decimal

      } catch (error) {
        console.error("Error fetching data:", error);
        setError("An error occurred while fetching the listing details.");
      } finally {
        setLoading(false);
      }
    };

    fetchListingData();
  }, [id]);

  const handleAddContact = async () => {
    setError(""); // Clear previous error

    // Check if sender and receiver are the same
    if (String(senderId) === String(listing.roomie_provider_id)) {
      setError("You cannot send a message to yourself.");
      return;
    }

    // Check if the message has already been sent
    if (messageSent) {
      setError("You have already sent a message to this provider.");
      return;
    }

    try {
      const message = `Hi, I am interested in your listing.`;
      const response = await fetch("https://roomiefinder.hopto.org:5000/chat", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          sender_id: senderId,
          receiver_id: listing.roomie_provider_id,
          message,
        }),
      });

      const data = await response.json();

      if (data.success) {
        setMessageSent(true); // Set messageSent to true to show success message
        setMessages((prevMessages) => [
          {
            sender_id: senderId,
            receiver_id: listing.roomie_provider_id,
            message,
          },
          ...prevMessages,
        ]); // Add the new message to the list of messages
      } else {
        setError("Failed to send the message.");
      }
    } catch (error) {
      console.error("Error sending message:", error);
      setError("An error occurred while sending the message.");
    }
  };

  function redirectToDashboard() {
    navigate("/finderDashboard");
  }

  if (loading) {
    return <p>Loading listing details...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  const { listing, roomDescription, amenities, roomAddress, pictures } =
    listingData;

  const address = `${roomAddress.house_number} ${roomAddress.street}, ${roomAddress.zip_code}, ${roomAddress.city}, ${roomAddress.state}, ${roomAddress.country}`;

  return (
    <div className="dashboard-container">
      <SideBar />
      <div className="main-content-area">
        <Header />
        <div className="single-listing-header-bar">
          <button className="back-button" onClick={redirectToDashboard}>
            Back to search
          </button>
        </div>
        <div className="single-listing-container">
          <div className="single-listing-card">
            <div className="single-listing-image-container">
              {pictures && pictures.length > 0 ? (
                pictures.map((picture, index) => (
                  <img
                    key={index}
                    src={picture.picture_path}
                    alt={`Room picture ${index + 1}`}
                    className="single-listing-image"
                  />
                ))
              ) : (
                <p>No pictures available for this listing.</p>
              )}
            </div>

            <div className="single-listing-info">
              <div className="left-section">
                <h3 className="rent">${roomDescription.rent}/mo</h3>
                <ul className="amenities">
                  <li>
                    Private{" "}
                    <span className="checkmark">
                      {roomDescription.room_type}
                    </span>
                  </li>
                  <li>
                    Furnished{" "}
                    <span className="checkmark">
                      {roomDescription.is_furnished ? "✓" : "✕"}
                    </span>
                  </li>
                  <li>
                    WiFi{" "}
                    <span className="checkmark">
                      {amenities.is_wifi ? "✓" : "✕"}
                    </span>
                  </li>
                  <li>
                    Parking{" "}
                    <span className="checkmark">
                      {amenities.is_parking ? "✓" : "✕"}
                    </span>
                  </li>
                  <li>
                    Laundry{" "}
                    <span className="checkmark">
                      {amenities.is_laundry ? "✓" : "✕"}
                    </span>
                  </li>
                  <li>
                    Accessible{" "}
                    <span className="checkmark">
                      {amenities.is_accessible ? "✓" : "✕"}
                    </span>
                  </li>
                </ul>
                <p className="address">{address}</p>
                <div className="room-provider-container">
                  <h4>
                    Room Provider:{" "}
                    {providerData
                      ? `${providerData.first_name} ${providerData.last_name}`
                      : "Loading..."}
                  </h4>
                  {providerData && providerData.profile_picture ? (
                    <img
                      src={providerData.profile_picture}
                      alt="Room Provider"
                      className="room-provider-img"
                    />
                  ) : (
                    <img
                      src={RoomProvider} // Default image if no profile picture
                      alt="Room Provider"
                      className="room-provider-img"
                    />
                  )}
                </div>
              </div>
              <div className="right-section">
                <h2 className="compatibility">
                  Compatibility: {compatibilityScore}/5
                </h2>
                {/* Nearby Colleges Section */}
                <div className="nearby-colleges">
                  <h4>Nearby Colleges</h4>
                  {nearbyColleges.length > 0 ? (
                    <ul className="college-list">
                      {nearbyColleges.map((college, index) => (
                        <li key={index}>{college.name}</li>
                      ))}
                    </ul>
                  ) : (
                    <div className="no-colleges">
                      <p>We don't have records of colleges in this city yet.</p>
                      <p>Contact us at support@roomiefinder.com for updates.</p>
                    </div>
                  )}
                </div>
                <div className="additional-notes-container">
                  <h3>
                    Additional Notes:
                    <span className="additional-notes">
                      {roomDescription.additional_notes}
                    </span>
                  </h3>
                </div>
              </div>
            </div>
          </div>
          <div className="single-listing-footer">
            Chat with{" "}
            {providerData
              ? `${providerData.first_name} ${providerData.last_name}`
              : "Room Provider"}{" "}
            to be their potential roommate!
            <button
              className="chat-prompt-button"
              onClick={(e) => {
                e.stopPropagation();
                handleAddContact();
              }}
            >
              Contact Now
            </button>
            {messageSent && (
              <p className="validation-message validation-message-success">
                Message sent successfully!
              </p>
            )}
            {error && <p className="validation-message">{error}</p>}
          </div>
        </div>
      </div>
    </div>
  );
}
