// List of colleges with their locations
export const colleges = [
    { name: "San Francisco State University", city: "San Francisco", latitude: 37.7241, longitude: -122.4799 },
    { name: "University of San Francisco", city: "San Francisco", latitude: 37.7766, longitude: -122.4506 },
    { name: "City College of San Francisco", city: "San Francisco", latitude: 37.7256, longitude: -122.4522 },
    { name: "UC Berkeley", city: "Berkeley", latitude: 37.8719, longitude: -122.2585 },
    { name: "Stanford University", city: "Stanford", latitude: 37.4275, longitude: -122.1697 },
    { name: "San Jose State University", city: "San Jose", latitude: 37.3352, longitude: -121.8811 },
    { name: "Santa Clara University", city: "Santa Clara", latitude: 37.3493, longitude: -121.9381 },
    { name: "UC Davis", city: "Davis", latitude: 38.5382, longitude: -121.7617 },
    { name: "Sacramento State", city: "Sacramento", latitude: 38.5611, longitude: -121.4240 },
    { name: "UC Santa Cruz", city: "Santa Cruz", latitude: 36.9916, longitude: -122.0583 }
];

/**
 * Calculates the distance between two points using the Haversine formula
 * @param {number} lat1 - Latitude of first point
 * @param {number} lon1 - Longitude of first point
 * @param {number} lat2 - Latitude of second point
 * @param {number} lon2 - Longitude of second point
 * @returns {number} Distance in miles
 */
export const calculateDistance = (lat1, lon1, lat2, lon2) => {
    const R = 3959; // Earth's radius in miles
    const dLat = (lat2 - lat1) * Math.PI / 180;
    const dLon = (lon2 - lon1) * Math.PI / 180;
    
    const a = 
        Math.sin(dLat/2) * Math.sin(dLat/2) +
        Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) * 
        Math.sin(dLon/2) * Math.sin(dLon/2);
    
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
    return R * c;
};

/**
 * Finds colleges within a specified radius of a given location
 * @param {number} latitude - Latitude of the location
 * @param {number} longitude - Longitude of the location
 * @param {number} radiusMiles - Search radius in miles
 * @returns {Array} Array of colleges with their distances
 */
export const findNearbyColleges = (latitude, longitude, radiusMiles = 25) => {
    return colleges.map(college => {
        const distance = calculateDistance(
            latitude,
            longitude,
            college.latitude,
            college.longitude
        );
        return {
            ...college,
            distance: Math.round(distance * 10) / 10 
        };
    }).filter(college => college.distance <= radiusMiles)
    .sort((a, b) => a.distance - b.distance);
};

/**
 * Finds colleges in a specific city
 * @param {string} city - The city to search for colleges
 * @returns {Array} Array of colleges in the city, limited to 3
 */
export const findCollegesByCity = (city) => {
    const cityColleges = colleges.filter(college => 
        college.city.toLowerCase() === city.toLowerCase()
    );
    return cityColleges.slice(0, 3);
};
