import DefaultPic from "../photos/defaultpic.png";
import EditIconImg from "../photos/ProfilePicEditIcon.png";

export default function ProfileLeftSection(props) {
  return (
    <div className="left-container">
      <div className="profile-pic-container" style={{ position: "relative" }}>
        <img 
          src={props.formData.profilePicture || DefaultPic} 
          alt="Profile pic" 
          className="profile-picture" 
        />
        
        {props.editProfile && (
          <>
            <button 
              className="edit-btn"
              style={{
                position: "absolute",
                bottom: "10px",  
                right: "10px",   
                background: "transparent",
                border: "none",
                cursor: "pointer",
              }}
              onClick={() => {
                document.getElementById("profile-picture-upload").click();
              }}
            >
              <img 
                src={EditIconImg} 
                alt="Edit Icon" 
                className="edit-icon" 
                style={{
                  width: "30px", 
                  height: "30px", 
                }}
              />
            </button>
            <input 
              type="file" 
              id="profile-picture-upload" 
              onChange={props.handleProfilePictureChange} 
              accept="image/*" 
              className="profile-picture-upload"
              style={{
                display: "none", // Hide the input element
              }}
            />
          </>
        )}
      </div>
      <br />
      <div className="name-container">
        <div className="first-name-container">
          <label className="name-label">First Name</label>
          {props.editProfile ? (
            <input
              type="text"
              name="firstName"
              className="first-name-input"
              placeholder="FirstName"
              onChange={props.handleChange}
              value={props.formData.firstName}
            />
          ) : (
            <p className="first-name-p">{props.formData.firstName}</p>
          )}
        </div>
        <div className="last-name-container">
          <label className="name-label">Last Name</label>
          {props.editProfile ? (
            <input
              type="text"
              name="lastName"
              className="last-name-input"
              placeholder="LastName"
              onChange={props.handleChange}
              value={props.formData.lastName}
            />
          ) : (
            <p className="last-name-p">{props.formData.lastName}</p>
          )}
        </div>
      </div>
      <label className="email-label">Email</label>
      {props.editProfile ? (
        <input
          type="email"
          name="email"
          className="email"
          placeholder="mail@mail.com"
          onChange={props.handleChange}
          value={props.formData.email}
        />
      ) : (
        <p className="email-p">{props.formData.email}</p>
      )}

      <label className="phone-number-label">Phone Number</label>
      {props.editProfile ? (
        <input
          type="text"
          name="phoneNumber"
          className="phone-number"
          placeholder="555-555-5555"
          onChange={props.handleChange}
          value={props.formData.phoneNumber}
        />
      ) : (
        <p className="phone-number-p">{props.formData.phoneNumber}</p>
      )}
    </div>
  );
}
