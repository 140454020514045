import React from "react";
import { useNavigate } from "react-router-dom";
import "./sidebar.css";

const Sidebar = ({ userType }) => {
  // Accept userType as a prop
  const navigate = useNavigate();

  return (
    <div className="sidebar">
      <div className="title-bar">
        Roomie {userType === "provider" ? "Provider" : "Finder"}
      </div>
      <div className="menu">
        {userType === "provider" ? (
          <>
            <div
              className="menu-item"
              onClick={() => navigate("/providerDashboard")}
            >
              <span className="icon">📊</span> Provider Dashboard
            </div>
            <div
              className="menu-item"
              onClick={() => navigate("/createListing")}
            >
              <span className="icon">🏠</span> Create Listing
            </div>
            <div
              className="menu-item"
              onClick={() => navigate("/chatProvider")}
            >
              <span className="icon">📬</span> Chat
            </div>
          </>
        ) : (
          <>
            <div
              className="menu-item"
              onClick={() => navigate("/finderDashboard")}
            >
              <span className="icon">📊</span> Finder Dashboard
            </div>
            <div className="menu-item" onClick={() => navigate("/roomielist")}>
              <span className="icon">📋</span> My Favorites
            </div>
            <div className="menu-item" onClick={() => navigate("/chatFinder")}>
              <span className="icon">💬</span> Chat
            </div>
          </>
        )}
        <div className="menu-item" onClick={() => navigate("/about")}>
          <span className="icon-about-us">ℹ️</span> About Us
        </div>
      </div>
      <div className="house-image">
        {/* <img src={houseImage} alt="Cool House" /> */}
      </div>

      <div className="menu-item switch" onClick={() => navigate("/rolepage")}>
        <span className="icon">🔄</span> Switch Roles
      </div>

      <div className="menu-item logout" onClick={() => navigate("/")}>
        <span className="icon">🚪</span> Log Out
      </div>
    </div>
  );
};

export default Sidebar;
