import React, { useState, useEffect } from "react";
import Header from "../components/dashboardHeader";
import Sidebar from "../components/sidebar";
import ListingCard from "../components/listingcard";
import { getSessionUserData } from "../userSession/session";
import { useNavigate } from "react-router-dom";
import "./roomieFinderDashboard.css";
import AWS from "aws-sdk";

const RoomieProviderDashboard = () => {
  const navigate = useNavigate();
  const [listings, setListings] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const userSessionData = getSessionUserData();
  const userId = userSessionData ? userSessionData.userId : null;
  

  useEffect(() => {
    const fetchListings = async () => {
      setLoading(true);
      setError(null);
    
      try {
        const response = await fetch(`https://roomiefinder.hopto.org:5000/filter/?roomie_provider_id=${userId}`);
        const data = await response.json();
        console.log(data);  
    
        if (data && Array.isArray(data.listings)) {
          const userOwnedListings = data.listings
          setListings(userOwnedListings);
        } else {
          setListings([]);
        }
      } catch (error) {
        console.error("Error fetching listings:", error);
        setError("Something went wrong while fetching the listings.");
      } finally {
        setLoading(false);
      }
    };
    fetchListings();
  }, []);

  const deleteOldPictures = async (pictures) => {
    if (!Array.isArray(pictures) || pictures.length === 0) {
      console.log("No pictures to delete.");
      return;
    }
  
    const s3 = new AWS.S3({
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
      region: process.env.REACT_APP_AWS_REGION,
    });
  
    const deletePromises = pictures.map((picture) => {
      const url = picture.picture_path;
  
      if (!url) {
        console.error(`Invalid picture object: ${JSON.stringify(picture)}`);
        return Promise.resolve(); 
      }
  
      let key = url.replace("https://csc648s3.s3.amazonaws.com/", "");
      key = decodeURIComponent(key); 
  
      const params = {
        Bucket: "csc648s3",
        Key: key,
      };
  
      return s3.deleteObject(params).promise()
        .then(() => console.log(`Successfully deleted picture: ${url}`))
        .catch((err) => {
          console.error(`Failed to delete picture: ${url}`, err);
        });
    });
  
    await Promise.all(deletePromises);
  };
  

  const handleEdit = (listingId) => {
    navigate(`/editListing?listingId=${listingId}`);
  };

  const handleDelete = async (listingId) => {
    if (!listingId) {
      console.error("Invalid listing ID");
      return;
    }
  
    const confirmDelete = window.confirm("Are you sure you want to delete this listing?");
    if (!confirmDelete) return;
  
    setLoading(true);
    setError(null);
  
    try {
      const response = await fetch(`https://roomiefinder.hopto.org:5000/listings/${listingId}`);
      const listingData = await response.json();
  
      if (listingData?.pictures?.length > 0) {
        await deleteOldPictures(listingData.pictures);
      }
  
      const deleteResponse = await fetch(`https://roomiefinder.hopto.org:5000/listings/${listingId}`, {
        method: "DELETE",
      });
  
      if (deleteResponse.ok) {
        console.log("Listing deleted successfully!");
        setListings((prevListings) => prevListings.filter((listing) => listing.listing_id !== listingId));
      } else {
        const data = await deleteResponse.json();
        setError(data.message || "Failed to delete the listing.");
      }
    } catch (error) {
      console.error("Error deleting listing:", error);
      setError("Something went wrong while deleting the listing.");
    } finally {
      setLoading(false);
    }
  };
  


  return (
    <div className="dashboard-container">
      <Sidebar userType="provider" />
      <div className="main-content-area">
        <Header />
        <div className="header-bar">
          <h2>Your Listings</h2>
        </div>
        <div className="listing-grid">
          {loading ? (
            <p>Loading...</p>
          ) : error ? (
            <p>{error}</p>
          ) : listings.length > 0 ? (
            listings.map((listing) => (
              <ListingCard
                key={listing.id}
                listing={listing}
                isEditMode={true}
                onEdit={() => handleEdit(listing.id)}
                onCancel={(id) => handleDelete(id)}
              />
            ))
          ) : (
            <p>No listings available.</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default RoomieProviderDashboard;
