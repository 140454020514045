import "./rolepage.css";
import { useNavigate } from "react-router-dom";
export default function RolePage() {
  const navigate = useNavigate();
  function redirectToRoomieFinder() {
    navigate("/finderDashboard");
    console.log("Redirecting to Roomie Finder page");
  }

  function redirectToRoomieProvider() {
    navigate("/providerDashboard");
    console.log("Redirecting to Roomie Provider page");
  }

  return (
    <div className="role-page">
      <div className="content-container">
        <div className="content-info">
          <h1 className="title">Which role would you like to take on?</h1>
          <button
            className="btn roomie-finder-btn"
            onClick={redirectToRoomieFinder}
          >
            Roomie Finder
          </button>
          <button
            className="btn roomie-provider-btn"
            onClick={redirectToRoomieProvider}
          >
            Roomie Provider
          </button>
        </div>
      </div>
    </div>
  );
}
