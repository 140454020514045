import React from 'react';
import Listings from '../components/listingssection.jsx';
import HeroSection from "../components/herosection.jsx";
import './homepage.css';

function Home() {
  return (
    <div className="home-container">
      <HeroSection />
      <Listings />
    </div>
  );
}

export default Home;
