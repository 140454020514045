export const fetchUserProfile = async () => {
    const userId = sessionStorage.getItem('userId');
  
    if (!userId) {
      console.error('User ID not found in session storage.');
      return;
    }
  
    try {
      const response = await fetch(`https://roomiefinder.hopto.org:5000/profile/${userId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
  
      if (response.ok) {
        const profileData = await response.json();
        console.log('Profile fetched successfully:', profileData);
        sessionStorage.setItem('profileData', JSON.stringify(profileData.profile));
      } else {
        console.error('Failed to fetch profile:', response.status);
      }
    } catch (error) {
      console.error('Error fetching profile:', error);
    }
  };
  
  export const getSessionUserData = () => {
    const userId = sessionStorage.getItem('userId');
    const profileData = sessionStorage.getItem('profileData');
      if (!userId || !profileData) {
      console.error('User ID or profile data not found in session storage.');
      return null;
    }
      return {
      userId,
      profile: JSON.parse(profileData),
    };
  };
  