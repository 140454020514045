import React from 'react';
import './TeamMemberCard.css';

const TeamMemberCard = ({ name, image, description, role }) => {
  return (
    <div className="team-member-card">
      <div className="image-container">
        <img src={image} alt={`${name}`} className="member-image" />
      </div>
      <div className="member-info">
        <h3 className="member-name">{name}</h3>
      </div>
    </div>
  );
};

export default TeamMemberCard;