import React, { useEffect, useState } from "react";
import "./listingcard.css";
import { getSessionUserData } from "../userSession/session";
import { useNavigate } from "react-router-dom";

export default function ListingCard({
  listing,
  onLike,
  isEditMode,
  onEdit,
  onCancel,
}) {
  const [providerName, setProviderName] = useState("");
  const [profilePicture, setProfilePicture] = useState("");
  const [messageSent, setMessageSent] = useState(false); // To track if the message was sent
  const [error, setError] = useState(""); // Error message state
  const [messages, setMessages] = useState([]); // Store the list of messages

  const userSessionData = getSessionUserData();
  const senderId = userSessionData ? userSessionData.userId : null;

  const navigate = useNavigate(); // Get navigate function from useNavigate

  // Fetch the provider's name and profile picture using the roomie_provider_id
  useEffect(() => {
    const fetchProviderProfile = async () => {
      try {
        const response = await fetch(`https://roomiefinder.hopto.org:5000/profile/${listing.roomie_provider_id}`);

        if (!response.ok) {
          throw new Error(`Failed to fetch provider data: ${response.statusText}`);
        }

        const data = await response.json();

        if (data.profile) {
          setProviderName(`${data.profile.first_name} ${data.profile.last_name}`);
          setProfilePicture(data.profile.profile_picture);
        } else {
          console.error("Profile not found in the response:", data);
          setProviderName("Unknown Provider");
          setProfilePicture("/default-profile-pic.png");
        }
      } catch (error) {
        console.error("Error fetching provider profile:", error);
        setProviderName("Unknown Provider");
        setProfilePicture("/default-profile-pic.png");
      }
    };

    fetchProviderProfile();
  }, [listing.roomie_provider_id]);

  const handleAddContact = async () => {
    setError(""); // Clear previous error
  
    // Check if sender and receiver are the same
    if (String(senderId) === String(listing.roomie_provider_id)) {
      setError("You cannot send a message to yourself.");
      return;
    }
  
    // Check if the message has already been sent
    if (messageSent) {
      setError("You have already sent a message to this provider.");
      return;
    }
  
    try {
      const message = `Hi, I am interested in your listing.`;
      const response = await fetch("https://roomiefinder.hopto.org:5000/chat", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          sender_id: senderId,
          receiver_id: listing.roomie_provider_id,
          message,
        }),
      });
  
      const data = await response.json();
  
      if (data.success) {
        setMessageSent(true); // Set messageSent to true to show success message
        setMessages((prevMessages) => [
          { sender_id: senderId, receiver_id: listing.roomie_provider_id, message },
          ...prevMessages,
        ]); // Add the new message to the list of messages
      } else {
        setError("Failed to send the message.");
      }
    } catch (error) {
      console.error("Error sending message:", error);
      setError("An error occurred while sending the message.");
    }
  };
  

  function redirectToSingleListingPage() {
    navigate(`/singleListing/${listing.listing_id}`); // Use navigate to go to the single listing page
  }

  function redirectToProviderProfile() {
    navigate(`/providerProfile/${listing.roomie_provider_id}`); // Use navigate to go to the provider profile
  }

  function redirectToEditListingPage() {
    navigate(`/editListing/${listing.listing_id}`);
  }

  const fullAddress = `${listing.house_number} ${listing.street}, ${listing.zip_code}, ${listing.city}, ${listing.state}, ${listing.country}`;

  return (
    <div className="listing-card" onClick={!isEditMode ? redirectToSingleListingPage : undefined}>
      <img
        src={listing.pictures && listing.pictures.length > 0 ? listing.pictures[0].picture_path : ""}
        alt=""
        style={{
          height: "20rem",
          width: "100%",
          objectFit: "cover",
        }}
      />
      <div className="price-container">
        <p className="price">${listing.rent}/mo</p>
        {!isEditMode && (
          <button
            className="heart-icon"
            onClick={(e) => {
              e.stopPropagation();
              onLike(listing.listing_id);
            }}
            aria-label={listing.liked ? "Unlike" : "Like"}
          >
            {listing.liked ? "❤️" : "🤍"}
          </button>
        )}
      </div>
      <p className="compatibility-score">Compatibility: {listing.score}/5</p>
      <ul className="features-list">
        <li className="feature-item">
          Furnished
          <span className="checkmark-icon">{listing.is_furnished ? "✓" : "✕"}</span>
        </li>
        <li className="feature-item">
          Wifi
          <span className="checkmark-icon">{listing.is_wifi ? "✓" : "✕"}</span>
        </li>
        <li className="feature-item">
          Parking
          <span className="checkmark-icon">{listing.is_parking ? "✓" : "✕"}</span>
        </li>
        <li className="feature-item">
          Laundry
          <span className="checkmark-icon">{listing.is_laundry ? "✓" : "✕"}</span>
        </li>
        <li className="feature-item">
          Accessible
          <span className="checkmark-icon">{listing.is_accessible ? "✓" : "✕"}</span>
        </li>
      </ul>
      <p className="address">{fullAddress}</p>
      <div className="room-provider">
        <span>Room Provider: {providerName}</span>
        {profilePicture && (
          <img
            src={profilePicture}
            alt={`${providerName}'s profile`}
            className="profile-pic"
            onClick={(e) => {
              e.stopPropagation();
              redirectToProviderProfile();
            }}
          />
        )}
      </div>
      {isEditMode ? (
        <div className="edit-buttons">
          <button
            className="edit-button"
            onClick={(e) => {
              e.stopPropagation();
              redirectToEditListingPage();
            }}
          >
            Edit
          </button>
          <button
            className="cancel-button"
            onClick={(e) => {
              e.stopPropagation();
              onCancel(listing.listing_id);
            }}
          >
            Cancel
          </button>
        </div>
      ) : (
        <div className="chat-prompt">
          Add {providerName} as a Contact!
          <button
            className="chat-prompt-button"
            onClick={(e) => {
              e.stopPropagation();
              handleAddContact();
            }}
          >
            Contact Now
          </button>

          {messageSent && (
            <p className="validation-message validation-message-success">
              Message sent successfully!
            </p>
          )}

          {error && (
            <p className="validation-message">
              {error}
            </p>
          )}       
        </div>
      )}
    </div>
  );
}
