import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import TermsOfService from "../components/termsOfService";
import { fetchUserProfile } from "../userSession/session";
import "./authenticationScreen.css";

function LandingPage() {
  const [isSignUp, setIsSignUp] = useState(false);
  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    gender: "",
    dob: "",
    phoneNumber: "",
    email: "",
    password: "",
    confirmPassword: "",
    tosAccepted: false,
  });
  const [showTermsOfService, setShowTermsOfService] = useState(false);
  const [customGender, setCustomGender] = useState(""); // state for custom gender input
  const [validationMessages, setValidationMessages] = useState([]); // state for validation messages
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (name === "dob") {
      // Allow free typing of date (do not block input)
      setFormData((prevState) => ({
        ...prevState,
        dob: value,
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [name]: type === "checkbox" ? checked : value,
      }));

      // If gender is set to "Other", update customGender in the formData
      if (name === "gender" && value !== "Other") {
        setFormData((prevState) => ({
          ...prevState,
          customGender: "", // Reset custom gender field when not "Other"
        }));
      }
    }
  };

  
  const handleCustomGenderChange = (e) => {
    setCustomGender(e.target.value);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
  
    let isValid = true;
    const messages = [];
    const regex = /^(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])\/(19|20)\d\d$/;
    const phoneRegex = /^(?:\(\d{3}\)\s?|\d{3}-)\d{3}-\d{4}$/;
  
    if (isSignUp) {
      // Check for empty or invalid fields
      if (!formData.firstname) {
        isValid = false;
        messages.push("First Name is required.");
      } else if (formData.firstname.length > 50) {
        isValid = false;
        messages.push("First Name cannot be more than 50 characters.");
      }
  
      if (!formData.lastname) {
        isValid = false;
        messages.push("Last Name is required.");
      } else if (formData.lastname.length > 50) {
        isValid = false;
        messages.push("Last Name cannot be more than 50 characters.");
      }
  
      if (!formData.email || !/\S+@\S+\.\S+/.test(formData.email)) {
        isValid = false;
        messages.push("Valid email is required.");
      } else if (formData.email.length > 100) {
        isValid = false;
        messages.push("Email cannot be more than 100 characters.");
      }
  
      if (!formData.password || formData.password.length < 6) {
        isValid = false;
        messages.push("Password must be at least 6 characters.");
      }
  
      if (formData.password !== formData.confirmPassword) {
        isValid = false;
        messages.push("Passwords do not match.");
      }
  
      if (!formData.tosAccepted) {
        isValid = false;
        messages.push("You must accept the Terms of Service.");
      }
  
      if (!formData.phoneNumber || !phoneRegex.test(formData.phoneNumber)) {
        isValid = false;
        messages.push("Please enter a valid phone number. (e.g., (123) 456-7890 or 123-456-7890)");
      }
  
      // Validate and format Date of Birth
      if (!formData.dob || formData.dob === '') {
        isValid = false;
        messages.push("Date of Birth is required.");
      } else if (!regex.test(formData.dob)) {
        isValid = false;
        messages.push("Invalid Date of Birth format. Please use MM/DD/YYYY.");
      }
    } else {
      // Login validation
      if (!formData.email || !/\S+@\S+\.\S+/.test(formData.email)) {
        isValid = false;
        messages.push("Valid email is required. Make sure this email is not used or is typed correctly");
      } else if (formData.email.length > 100) {
        isValid = false;
        messages.push("Email cannot be more than 100 characters.");
      }
  
      if (!formData.password) {
        isValid = false;
        messages.push("Password is required.");
      }
    }
  
    // If form is invalid, display messages
    if (!isValid) {
      setValidationMessages(messages); // Set validation messages to state
      setTimeout(() => setValidationMessages([]), 3000); // Clear messages after 3 seconds
      return;
    } else {
      // Prepare the formatted Date of Birth for the API call (convert to YYYY-MM-DD)
      let formattedDob = formData.dob;
      if (formattedDob && regex.test(formattedDob)) {
        const [month, day, year] = formattedDob.split("/");
        formattedDob = `${year}-${month}-${day}`;
      }
  
      // Proceed with form submission (sign up or login)
      if (isSignUp) {
        // Form validation
        if (!formData.firstname) {
          isValid = false;
          messages.push("First Name is required.");
        }
        if (!formData.lastname) {
          isValid = false;
          messages.push("Last Name is required.");
        }
        if (!formData.email || !/\S+@\S+\.\S+/.test(formData.email)) {
          isValid = false;
          messages.push("Valid email is required.");
        }
        if (!formData.password || formData.password.length < 6) {
          isValid = false;
          messages.push("Password must be at least 6 characters.");
        }
        if (formData.password !== formData.confirmPassword) {
          isValid = false;
          messages.push("Passwords do not match.");
        }
    
        if (isValid) {
          const userData = {
            firstName: formData.firstname,
            lastName: formData.lastname,
            dateOfBirth: formattedDob, // Use formatted DOB for the API
            gender: formData.gender === "Other" ? customGender : formData.gender, // Use custom gender if "Other" is selected
            phoneNumber: formData.phoneNumber,
            email: formData.email,
            password: formData.password,
            current_role: "roommate_finder",
          };
    
          try {
            const response = await fetch("https://roomiefinder.hopto.org:5000/signup", {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(userData),
            });
    
            if (response.ok) {
              const signupData = await response.json();
              const userId = signupData.userId;
              console.log("User ID:", userId);
              sessionStorage.setItem('userId', userId);
              navigate("/quiz");
            } else {
              // Handle error specific to signup
              console.log('Response status:', response.status);
              if (response.status === 409) {
                messages.push("Email is already in use. Please use a different email address.");
              } else {
                console.error("Signup failed with status:", response.status);
                messages.push("Signup failed. Please try again later.");
              }
            }
          } catch (error) {
            console.error("Error during sign-up:", error);
            messages.push("An error occurred during sign-up. Please try again later.");
          }
        }
      } else {
        // Handle login
        const loginData = {
          email: formData.email,
          password: formData.password,
        };
    
        try {
          const response = await fetch("https://roomiefinder.hopto.org:5000/login", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(loginData),
          });
    
          if (response.ok) {
            const loginResponse = await response.json();
            console.log("Login successful", loginResponse);
            sessionStorage.setItem("userId", loginResponse.userId);
            console.log("User ID stored in session storage:", loginResponse.userId);
            await fetchUserProfile();
            navigate("/role");
          } else {
            // Handle error specific to login
            console.log('Login response status:', response.status);
            if (response.status === 401) {
              messages.push("Invalid email or password. Please try again.");
            } else {
              messages.push("Login failed. Please try again later.");
            }
          }
        } catch (error) {
          console.error("Error during login:", error);
          messages.push("An error occurred during login. Please try again later.");
        }
      }
      setValidationMessages(messages);
    }
    };

  return (
    <div className="landing-page">
      { }
      {validationMessages.length > 0 && (
        <div className="validation-message">
          {validationMessages.map((msg, index) => (
            <p key={index}>{msg}</p>
          ))}
        </div>
      )}

      <div className="container">
        <div className="left-side">
          <h1>Welcome to Roomie Finder</h1>
          <p>Your journey to finding the perfect roommate starts here!</p>
          <button onClick={() => setIsSignUp(!isSignUp)} className="toggle-btn">
            {isSignUp ? "Already have an account? Log In" : "New here? Sign Up"}
          </button>
        </div>
        <div className="right-side">
          <h2>{isSignUp ? "Create an Account" : "Log In"}</h2>
          <form onSubmit={handleFormSubmit} className="auth-form">
            {isSignUp && (
              <>
                <input
                  type="text"
                  name="firstname"
                  placeholder="First Name"
                  className="auth-input"
                  value={formData.firstname}
                  onChange={handleInputChange}
                />
                <input
                  type="text"
                  name="lastname"
                  placeholder="Last Name"
                  className="auth-input"
                  value={formData.lastname}
                  onChange={handleInputChange}
                />
                <select
                  name="gender"
                  className="auth-input"
                  value={formData.gender}
                  onChange={handleInputChange}
                >
                  <option value="">Select Gender</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="Non-binary">Non-binary</option>
                  <option value="Genderqueer">Genderqueer</option>
                  <option value="Prefer not to say">Prefer not to say</option>
                  <option value="Other">Specify</option>
                </select>
                {formData.gender === "Other" && (
                  <input
                    type="text"
                    name="customGender"
                    placeholder="Please specify"
                    className="auth-input"
                    value={customGender}
                    onChange={handleCustomGenderChange}
                  />
                )}
                <input
                  type="text"
                  id="dob"
                  name="dob"
                  className="auth-input"
                  placeholder="MM/DD/YYYY"
                  value={formData.dob}
                  onChange={handleInputChange}
                />
                <input
                  type="tel"
                  name="phoneNumber"
                  placeholder="555-555-5555"
                  className="auth-input"
                  value={formData.phoneNumber}
                  onChange={handleInputChange}
                />
              </>
            )}
            <input
              type="email"
              name="email"
              placeholder="Email"
              className="auth-input"
              value={formData.email}
              onChange={handleInputChange}
            />
            <input
              type="password"
              name="password"
              placeholder="Password"
              className="auth-input"
              value={formData.password}
              onChange={handleInputChange}
            />
            {isSignUp && (
              <>
                <input
                  type="password"
                  name="confirmPassword"
                  placeholder="Confirm Password"
                  className="auth-input"
                  value={formData.confirmPassword}
                  onChange={handleInputChange}
                />
                <div className="tos-checkbox">
                  <input
                    type="checkbox"
                    name="tosAccepted"
                    checked={formData.tosAccepted}
                    onChange={handleInputChange}
                  />
                  <label
                    htmlFor="tosAccepted"
                    onClick={() => setShowTermsOfService(!showTermsOfService)}
                  >
                    I agree to the Terms of Service
                  </label>
                  {showTermsOfService && (
                    <TermsOfService
                      handleCloseBtn={() => setShowTermsOfService(false)}
                    />
                  )}
                </div>
              </>
            )}
            <button type="submit" className="signup-login-btn">
              {isSignUp ? "Sign Up" : "Log In"}
            </button>
          </form>
        </div>
      </div>
    </div>
  );

}

export default LandingPage;
