import React, { useState, useEffect, useCallback } from "react";
import Header from "../components/dashboardHeader";
import Sidebar from "../components/sidebar";
import FilterHeader from "../components/dashboardFilter";
import ListingCard from "../components/listingcard";
import { getSessionUserData } from "../userSession/session";
import "./roomieFinderDashboard.css";

const RoomieFinderDashboard = () => {
  const [filters, setFilters] = useState({
    is_wifi: "",
    is_parking: "",
    is_laundry: "",
    is_accessible: "",
    search_query: "",
    cost: "",
  });
  const [listings, setListings] = useState([]);
  const [favorites, setFavorites] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [userId, setUserId] = useState(null);
  const [showCookieBanner, setShowCookieBanner] = useState(false);
  useEffect(() => {
    const sessionData = getSessionUserData();
    if (sessionData) {
      setUserId(sessionData.userId);
    } else {
      console.error("Failed to retrieve user session data.");
    }
    const cookieBannerDismissed = localStorage.getItem("cookieBannerDismissed");
    if (!cookieBannerDismissed) {
      setShowCookieBanner(true);
    }
  }, []);
  const handleDismissBanner = () => {
    localStorage.setItem("cookieBannerDismissed", "true");
    setShowCookieBanner(false);
  };

  // Fetch favorited listings
  const fetchFavorites = useCallback(async () => {
    try {
      const response = await fetch(`https://roomiefinder.hopto.org:5000/favorites/${userId}`);
      if (!response.ok) {
        throw new Error("Failed to fetch favorites.");
      }
      const data = await response.json();
      setFavorites(data.map((favorite) => favorite.listing_id));
    } catch (error) {
      console.error("Error fetching favorites:", error);
    }
  }, [userId]);

  // Fetch all listings with filters
  const fetchListings = useCallback(() => {
    const queryString = new URLSearchParams(filters).toString();

    setLoading(true);
    setError(null);

    fetch(`https://roomiefinder.hopto.org:5000/filter?${queryString}`)
      .then((res) => res.json())
      .then(async (data) => {
        if (data && Array.isArray(data.listings)) {
          const sessionData = getSessionUserData(); // Get logged-in user's profile
          if (!sessionData || !sessionData.profile) {
            throw new Error("Failed to retrieve user profile for compatibility calculation.");
          }
          const userProfile = sessionData.profile;

          // Calculate compatibility for each listing
          const updatedListings = await Promise.all(
            data.listings.map(async (listing) => {
              const providerResponse = await fetch(`https://roomiefinder.hopto.org:5000/profile/${listing.roomie_provider_id}`);
              const providerData = await providerResponse.json();

              const providerProfile = providerData.profile;

              // Compatibility calculation
              const attributes = ["sleep_schedule", "is_smoke", "is_pets", "cleanliness_level", "social_environment"];
              let score = 0;

              attributes.forEach((attr) => {
                if (userProfile[attr] === providerProfile[attr]) {
                  score += 1;
                }
              });

              const normalizedScore = (score / attributes.length) * 5; // Normalize score to a scale of 5

              return {
                ...listing,
                liked: favorites.includes(listing.listing_id), // Check if the listing is already liked
                score: normalizedScore, // Add compatibility score
              };
            })
          );

          // Sort listings if needed
          let sortedListings = updatedListings;

          if (filters.cost === "low") {
            sortedListings = sortedListings.sort((a, b) => a.rent - b.rent);
          } else if (filters.cost === "high") {
            sortedListings = sortedListings.sort((a, b) => b.rent - a.rent);
          }

          setListings(sortedListings);
        } else {
          setListings([]);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching listings:", error);
        setError("Something went wrong while fetching the listings.");
        setLoading(false);
        setListings([]);
      });
  }, [filters, favorites]);


  useEffect(() => {
    fetchFavorites();
  }, [fetchFavorites]);

  useEffect(() => {
    fetchListings();
  }, [filters, fetchListings]);
  useEffect(() => {
    getSessionUserData();
  }, []);

  const handleFilterChange = (type, value) => {
    if (type === "apply") {
    } else {
      setFilters((prevFilters) => ({ ...prevFilters, [type]: value }));
    }
  };

  const handleLike = async (id) => {
    const isLiked = favorites.includes(id);

    // Optimistically update UI
    setFavorites((prevFavorites) =>
      isLiked ? prevFavorites.filter((favId) => favId !== id) : [...prevFavorites, id]
    );

    if (isLiked) {
      try {
        const response = await fetch(`https://roomiefinder.hopto.org:5000/favorites`, {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            user_id: userId,
            listing_id: id,
          }),
        });

        if (!response.ok) {
          throw new Error("Failed to remove favorite.");
        }
      } catch (error) {
        console.error("Error removing favorite:", error);
        alert("An error occurred while removing the listing from favorites.");
        setFavorites((prevFavorites) => [...prevFavorites, id]);
      }
    } else {
      try {
        const response = await fetch(`https://roomiefinder.hopto.org:5000/favorites`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            user_id: userId,
            listing_id: id,
          }),
        });

        if (!response.ok) {
          throw new Error("Failed to add favorite.");
        }
      } catch (error) {
        console.error("Error adding favorite:", error);
        alert("An error occurred while adding the listing to favorites.");
        setFavorites((prevFavorites) =>
          prevFavorites.filter((favId) => favId !== id)
        ); // Revert UI update
      }
    }
  };

  return (
    <div className="dashboard-container">

      <Sidebar userType="finder" />
      <div className="main-content-area">
        <Header />
        {showCookieBanner && (
          <div className="cookie-banner">
            <p>
              We currently don't use cookies, but in the future, we might. You can choose to{" "}
              <strong
                onClick={handleDismissBanner}
                style={{ cursor: "pointer", color: "white", textDecoration: "underline" }}
              >
                Accept
              </strong>{" "}
              or{" "}
              <strong
                onClick={handleDismissBanner}
                style={{ cursor: "pointer", color: "white", textDecoration: "underline" }}
              >
                Opt-out
              </strong>
              .
            </p>
          </div>
        )}

        <FilterHeader onFilterChange={handleFilterChange} />
        <div className="listing-grid">
          {loading ? (
            <p>Loading...</p>
          ) : error ? (
            <p>{error}</p>
          ) : listings.length > 0 ? (
            listings.map((listing) => (
              <ListingCard
                key={listing.listing_id}
                listing={listing}
                onLike={handleLike}
              />
            ))
          ) : (
            <p>No listings available.</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default RoomieFinderDashboard;