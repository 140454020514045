export default function ProfileRightSection(props) {
  const remainingChars = 500 - (props.formData.bio ? props.formData.bio.length : 0);


  return (
    <div className="right-container">
      <label htmlFor="bio" className="bio-label">
        Bio
      </label>
      {props.editProfile ? (
        <>
          <textarea
            id="bio"
            type="text"
            name="bio"
            className="bio"
            placeholder="Hello! Enter Your Bio Here!"
            onChange={props.handleChange}
            value={props.formData.bio}
            maxLength={500}  
          />
          {}
          <div className="character-count">
            {remainingChars} characters remaining
          </div>
        </>
      ) : (
        <p className="bio-p">{props.formData.bio}</p>
      )}

      <label className="roommate-preference-label">Roommate Preferences</label>
      <div className="preferences-container">
        <div className="label-container">
          <label htmlFor="smoke" className="smoke-label">
            Smoke-Free:
          </label>
          <label htmlFor="pets" className="pets-label">
            Pets:
          </label>
          <label htmlFor="cleanliness" className="cleanliness-label">
            Cleanliness:
          </label>
          <label
            htmlFor="social-environment"
            className="social-environment-label"
          >
            Social Environment:
          </label>
          <label htmlFor="sleep-schedule" className="sleep-schedule-label">
            Sleep Schedule:
          </label>
        </div>
        <div className="dropdown-container">
          {props.editProfile ? (
            <select
              id="smoke"
              className="smoke"
              name="smoke"
              value={props.formData.smoke}
              onChange={props.handleChange}
            >
              <option value="No">No</option>
              <option value="Yes">Yes</option>
            </select>
          ) : (
            <p className="smoke-p">{props.formData.smoke}</p>
          )}
          {props.editProfile ? (
            <select
              id="pets"
              className="pets"
              name="pets"
              value={props.formData.pets}
              onChange={props.handleChange}
            >
              <option value="No">No</option>
              <option value="Yes">Yes</option>
            </select>
          ) : (
            <p className="pets-p">{props.formData.pets}</p>
          )}
          {props.editProfile ? (
            <select
              id="cleanliness"
              className="cleanliness"
              name="cleanliness"
              value={props.formData.cleanliness}
              onChange={props.handleChange}
            >
              <option value="Very Clean">Very Clean</option>
              <option value="Clean">Clean</option>
              <option value="Moderate">Moderate</option>
              <option value="Messy">Messy</option>
              <option value="Very Messy">Very Messy</option>
            </select>
          ) : (
            <p className="cleanliness-p">{props.formData.cleanliness}</p>
          )}
          {props.editProfile ? (
            <select
              id="social-environment"
              className="social-environment"
              name="socialEnvironment"
              value={props.formData.socialEnvironment}
              onChange={props.handleChange}
            >
              <option value="Quiet">Quiet</option>
              <option value="Moderate">Moderate</option>
              <option value="Lively">Lively</option>
              <option value="Party Animal">Party Animal</option>
              <option value="Very Social">Very Social</option>
            </select>
          ) : (
            <p className="social-environment-p">
              {props.formData.socialEnvironment}
            </p>
          )}
          {props.editProfile ? (
            <select
              id="sleep-schedule"
              className="sleep-schedule"
              name="sleepSchedule"
              value={props.formData.sleepSchedule}
              onChange={props.handleChange}
            >
              <option value="Early Bird">Early Bird</option>
              <option value="Night Owl">Night Owl</option>
              <option value="Flexible">Flexible</option>
              <option value="Mixed Schedule">Mixed Schedule</option>
            </select>
          ) : (
            <p className="sleep-schedule-p">{props.formData.sleepSchedule}</p>
          )}
        </div>
      </div>
    </div>
  );
}
