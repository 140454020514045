import "./profilepage.css";
import ProfileNavBar from "../components/ProfileNavBar.jsx";
import ProfileLeftSection from "../components/ProfileLeftSection.jsx";
import ProfileRightSection from "../components/ProfileRIghtSection.jsx";
import { fetchUserProfile, getSessionUserData } from "../userSession/session";
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from "react";
import AWS from 'aws-sdk';

export default function ProfilePage() {
  const navigate = useNavigate();
  const [editProfile, setEditProfile] = useState(false);
  const [message, setMessage] = useState("");  // Added state for message
  const [messageType, setMessageType] = useState("");  // Added state for message type (validation/success)
  const { userId } = getSessionUserData();
console.log("User ID from session:", userId);


  const [formData, setFormData] = useState({
    userId: userId,
    firstName: "",
    lastName: "",
    bio: "",
    dob: "",
    gender: "",
    profilePicture: "",
    phoneNumber: "",
    email: "",
    smoke: "",
    pets: "",
    cleanliness: "",
    socialEnvironment: "",
    sleepSchedule: "",
  });

  // Fetch profile data
  useEffect(() => {
    if (userId) {
      fetch(`https://roomiefinder.hopto.org:5000/profile/${userId}`)
        .then((response) => {
          if (!response.ok) {
            throw new Error("Failed to fetch profile");
          }
          return response.json();
        })
        .then((data) => {
          setFormData((prev) => ({
            ...prev,
            firstName: data.profile.first_name,
            lastName: data.profile.last_name,
            bio: data.profile.bio,
            dob: data.profile.date_of_birth || "",
            gender: data.profile.gender || "",
            profilePicture: data.profile.profile_picture || "",
            phoneNumber: data.profile.phone_number || "",
            email: data.profile.email || "",
            smoke: data.profile.is_smoke ? "Yes" : "No",
            pets: data.profile.is_pets ? "Yes" : "No",
            cleanliness: data.profile.cleanliness_level || "",
            socialEnvironment: data.profile.social_environment || "",
            sleepSchedule: data.profile.sleep_schedule || "",
          }));
        })
        .catch((err) => {
          console.error("Error fetching profile:", err);
        });
    }
  }, [userId]);

  // Handle form input change
  function handleInputChange(event) {
    const { name, value } = event.target;

    // Enforce character limits
    if (name === "firstName" || name === "lastName") {
      if (value.length <= 50) {
        setFormData((prev) => ({
          ...prev,
          [name]: value,
        }));
      }
    } else if (name === "email") {
      if (value.length <= 100) {
        setFormData((prev) => ({
          ...prev,
          [name]: value,
        }));
      }
    } else {
      setFormData((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  }

  // Handle profile edit click
  function handleEditClick() {
    if (editProfile) {
      saveChanges();
    } else {
      setEditProfile(true);
    }
  }

  function isValidEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }

  // Add phone number validation
  function isValidPhoneNumber(phoneNumber) {
    const phoneRegex = /^(?:\(\d{3}\)\s?|\d{3}-)\d{3}-\d{4}$/;
    return phoneRegex.test(phoneNumber);
  }

  // Save changes to the profile
  function saveChanges() {
    if (formData.firstName.length > 50 || formData.lastName.length > 50) {
      setMessage("First and last names must be 50 characters or less.");
      setMessageType("validation");
      resetMessage();
      return;
    }

    if (formData.email.length > 100) {
      setMessage("Email must be 100 characters or less.");
      setMessageType("validation");
      resetMessage();
      return;
    }

    if (!isValidEmail(formData.email)) {
      setMessage("Please enter a valid email address.");
      setMessageType("validation");
      resetMessage();
      return;
    }

    if (!isValidPhoneNumber(formData.phoneNumber)) {
      setMessage("Please enter a valid phone number. (e.g., (123) 456-7890 or 123-456-7890)");
      setMessageType("validation");
      resetMessage();
      return;
    }

    fetch(`https://roomiefinder.hopto.org:5000/profile/${userId}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((response) => {
        if (response.status === 400) {
          throw new Error("Email is already in use by another user. Please use a different email.");
        }
        if (!response.ok) {
          throw new Error("Failed to save profile. Please try again later.");
        }
        return response.json();
      })
      .then((data) => {
        console.log("Profile updated:", data);
        setEditProfile(false); // Exit edit mode after successful save
        setMessage("Profile updated successfully!");
        setMessageType("success");
        resetMessage();
      })
      .catch((err) => {
        setMessage(err.message);  // Show error message
        setMessageType("validation");
        resetMessage();
        console.error("Error updating profile:", err);
      });
      fetchUserProfile();
  }

  // Function to reset the message after a timeout
  function resetMessage() {
    setTimeout(() => {
      setMessage("");  // Clear the message after 5 seconds
    }, 5000);
  }

  // Go back to the previous page
  function goBack() {
    navigate("/finderDashboard");
  }

  // Function to upload profile picture to S3
  const uploadToS3 = async (file) => {
    const s3 = new AWS.S3({
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
      region: process.env.REACT_APP_AWS_REGION,
    });

    const params = {
      Bucket: "csc648s3",
      Key: `uploads/${Date.now()}-${file.name}`,
      Body: file,
      ACL: "public-read",
    };

    try {
      console.log("Uploading to S3...");
      const data = await s3.upload(params).promise();
      console.log("Upload success:", data.Location);
      return data.Location;
    } catch (err) {
      console.error("S3 Upload error:", err);
      throw err;
    }
  };

  // Function to delete old profile pictures
  const deleteOldPictures = async () => {
    const oldProfilePicture = formData.profilePicture;
    if (!oldProfilePicture) {
      console.log("No old profile picture to delete.");
      return;
    }

    const s3 = new AWS.S3({
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
      region: process.env.REACT_APP_AWS_REGION,
    });

    const key = oldProfilePicture.replace("https://csc648s3.s3.amazonaws.com/", "");

    const params = {
      Bucket: "csc648s3",
      Key: decodeURIComponent(key),
    };

    try {
      await s3.deleteObject(params).promise();
      console.log(`Successfully deleted old picture: ${oldProfilePicture}`);
    } catch (err) {
      console.error(`Failed to delete old picture: ${oldProfilePicture}`, err);
    }
  };

  // Handle file input change (for profile picture upload)
  function handleProfilePictureChange(event) {
    const file = event.target.files[0];

    if (file) {
      // First, delete the old profile picture before uploading a new one
      deleteOldPictures()
        .then(() => uploadToS3(file))
        .then((uploadedImageUrl) => {
          // Once upload is successful, update the profile picture URL in formData
          setFormData((prev) => ({
            ...prev,
            profilePicture: uploadedImageUrl,
          }));
        })
        .catch((err) => {
          console.error("Error uploading profile picture:", err);
        });
    }
  }

  return (
    <div className="profile-page">
      <div className="profile-content-container">
        <ProfileNavBar
          saveChanges={saveChanges}
          editProfile={editProfile}
          goBack={goBack}
          handleEditClick={handleEditClick} 
        />
        <div className="profile-info">
          <ProfileLeftSection
            editProfile={editProfile}
            formData={formData}
            handleChange={handleInputChange}
            handleProfilePictureChange={handleProfilePictureChange} 
          />
          <ProfileRightSection
            editProfile={editProfile}
            formData={formData}
            handleChange={handleInputChange}
            handleProfilePictureChange={handleProfilePictureChange}
          />
        </div>
      </div>
      {/* Display message */}
      {message && (
        <div className={messageType === "success" ? "filter-message" : "validation-message"}>
          {message}
        </div>
      )}
    </div>
  );
}