import React, { useState, useEffect, useCallback } from "react";
import './listingssection.css';
import ListingCard from './listingitem.jsx';

function Listings() {
  const [listings, setListings] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchListings = useCallback(() => {
    setLoading(true);
    setError(null);

    const queryUrl = searchQuery ? `https://roomiefinder.hopto.org:5000/filter?search_query=${encodeURIComponent(searchQuery)}` : `https://roomiefinder.hopto.org:5000/filter`;

    fetch(queryUrl)
      .then((res) => res.json())
      .then((data) => {
        console.log("Fetched Data:", data);

        if (data && Array.isArray(data.listings)) {
          const transformedListings = data.listings.map((item) => ({
            id: item.listing_id,
            price: parseFloat(item.rent),
            score: 0, // Assuming score isn't provided in the data; use a default or remove if unnecessary
            private: item.room_type === "Single Room", // Adjust logic based on your room type
            furnished: item.is_furnished === 1,
            wifi: item.is_wifi === 1,
            parking: item.is_parking === 1,
            laundry: item.is_laundry === 1,
            accessible: item.is_accessible === 1,
            address: `${item.house_number} ${item.street}, ${item.city}, ${item.state}, ${item.zip_code}`,
            providerName: "Unknown", // Placeholder if provider name isn't included
            image: item.pictures && item.pictures.length > 0 ? item.pictures[0].picture_path.replace(/\\/g, "/") : "",
            profilePicture: "", // No profile picture in the data; add a default or placeholder
          }));

          setListings(transformedListings);
        } else {
          setListings([]);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.error("Error fetching listings:", err);
        setError("Something went wrong while fetching the listings.");
        setLoading(false);
        setListings([]);
      });
  }, [searchQuery]);

  useEffect(() => {
    fetchListings();
  }, [searchQuery, fetchListings]);

  const handleLike = (id) => {
    setListings((prevListings) =>
      prevListings.map((listing) =>
        listing.id === id ? { ...listing, liked: !listing.liked } : listing
      )
    );
  };

  return (
    <div className="listings">
      <input
        type="text"
        placeholder="Search with (City or City, State)"
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        className="search-input"
      />
      <div className="listings-container">
        {loading ? (
          <p>Loading...</p>
        ) : error ? (
          <p>{error}</p>
        ) : listings.length > 0 ? (
          listings.map((listing) => (
            <ListingCard key={listing.id} listing={listing} onLike={handleLike} />
          ))
        ) : (
          <p>No listings available.</p>
        )}
      </div>
    </div>
  );
}

export default Listings;
