import { useState, useEffect } from "react";
import ListingCard from "../components/listingcard.jsx";
import SideBar from "../components/sidebar.jsx";
import Header from "../components/dashboardHeader.jsx";
import "./roomielist.css";

export default function RoomieList() {
  const [listings, setListings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const userId = sessionStorage.getItem('userId');

  useEffect(() => {
    const fetchFavorites = async () => {
      setLoading(true);
      setError(null);

      try {
        const response = await fetch(`https://roomiefinder.hopto.org:5000/favorites/${userId}`);
        if (!response.ok) {
          if (response.status === 404) {
            setListings([]);
          } else {
            throw new Error("Failed to fetch favorite listings.");
          }
        } else {
          const data = await response.json();
          setListings(
            data.map((listing) => ({
              ...listing,
              liked: true,
            }))
          );
        }
      } catch (err) {
        console.error("Error fetching favorites:", err);
        setError("An error occurred while fetching your favorite listings.");
      } finally {
        setLoading(false);
      }
    };

    fetchFavorites();
  }, [userId]);

  const handleLike = async (id) => {
    try {
      const listing = listings.find((listing) => listing.listing_id === id);
  
      if (listing.liked) {
        const response = await fetch(`https://roomiefinder.hopto.org:5000/favorites`, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            user_id: userId,
            listing_id: id,
          }),
        });
  
        if (!response.ok) {
          const errorData = await response.json();
          console.error('Error removing favorite:', errorData.message);
          alert(errorData.message);
          return;
        }
  
        setListings((prevListings) =>
          prevListings.filter((listing) => listing.listing_id !== id)
        );
      } else {
        const response = await fetch(`https://roomiefinder.hopto.org:5000/favorites`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            user_id: userId,
            listing_id: id,
          }),
        });
  
        if (!response.ok) {
          const errorData = await response.json();
          console.error('Error adding to favorites:', errorData.message);
          alert(errorData.message);
          return;
        }
  
        const updatedListings = listings.map((listing) =>
          listing.listing_id === id ? { ...listing, liked: true } : listing
        );
        setListings(updatedListings);
      }
    } catch (error) {
      console.error('Error updating favorites:', error);
      alert('An error occurred while updating the favorites list.');
    }
  };
  

  return (
    <div className="page-container">
      <SideBar userType="finder" />
      <div className="main-content-area">
        <Header />
        <div className="header-bar">
          <h2>Your Favorite Listings</h2>
        </div>
        <div className="listings-container">
          {loading ? (
            <p>Loading...</p>
          ) : error ? (
            <p>{error}</p>
          ) : listings.length > 0 ? (
            listings.map((listing) => (
              <ListingCard
                key={listing.listing_id}
                listing={listing}
                onLike={handleLike}
              />
            ))
          ) : (
            <p>No favorite listings found.</p>
          )}
        </div>
      </div>
    </div>
  );
}
