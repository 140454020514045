import React from 'react';
import './listingssection.css';

export default function ListingCard({ listing, onLike }) {
    return (
        <div className="listing-card">  
            <img
                src={listing.image}
                alt={`Room listing for ${listing.providerName}`}
                className="listing-image"
            />
            <div className="price-container">
                <p className="price">${listing.price}/mo</p>
            </div>
            <ul className="features-list">
                <li className="feature-item">
                    Private
                    <span className="checkmark-icon">
                        {listing.private ? '✓' : '✕'}
                    </span>
                </li>
                <li className="feature-item">
                    Furnished
                    <span className="checkmark-icon">
                        {listing.furnished ? '✓' : '✕'}
                    </span>
                </li>
                <li className="feature-item">
                    Wifi
                    <span className="checkmark-icon">
                        {listing.wifi ? '✓' : '✕'}
                    </span>
                </li>
                <li className="feature-item">
                    Parking
                    <span className="checkmark-icon">
                        {listing.parking ? '✓' : '✕'}
                    </span>
                </li>
                <li className="feature-item">
                    Laundry
                    <span className="checkmark-icon">
                        {listing.laundry ? '✓' : '✕'}
                    </span>
                </li>
                <li className="feature-item">
                    Accessible
                    <span className="checkmark-icon">
                        {listing.accessible ? '✓' : '✕'}
                    </span>
                </li>
            </ul>
            <p className="address">{listing.address}</p>
        </div>
    );
}
