import React from "react";
import "./chatcontacts.css";
// import DefaultProfilePic from "../photos/contact.jpg";

const ChatCard = ({ profilePic, name, lastMessage }) => {
  //if no profile picture, a default picture is assigned
  // const profileImage = profilePic || DefaultProfilePic;

  return (
      <div className="chat-card" >
        <img 
          src={profilePic} 
          alt={`${name}'s profile`} 
          className="profile-pic" 
        />
        <div className="chat-details">
          <p className="chat-name">{name}</p>
          <p className="chat-message">{lastMessage}</p>
        </div>
      </div>

  );
};  

export default ChatCard;














































