import "./providerprofile.css";
import Header from "../components/dashboardHeader";
import Sidebar from "../components/sidebar";
import { useNavigate, useParams } from "react-router-dom";
import { useState, useEffect } from "react";

export default function ProviderProfile() {
  const navigate = useNavigate();
  const { userId } = useParams();  // userId will correspond to the roomie_provider_id

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    college: "",
    bio: "",
    smoke: "",
    pets: "",
    cleanliness: "",
    socialEnvironment: "",
    sleepSchedule: "",
    profilePicture: "",
  });

  const redirectToDashboard = () => {
    navigate("/finderDashboard");
  };

  useEffect(() => {
    const fetchProviderProfile = async () => {
      try {
        const response = await fetch(`https://roomiefinder.hopto.org:5000/profile/${userId}`);

        if (!response.ok) {
          throw new Error(`Failed to fetch provider data: ${response.statusText}`);
        }

        // Parse the response as JSON
        const data = await response.json();

        // Check if the response contains the expected 'profile' object
        if (data.profile) {
          setFormData({
            firstName: data.profile.first_name,
            lastName: data.profile.last_name,
            email: data.profile.email,
            phoneNumber: data.profile.phone_number,
            bio: data.profile.bio || "N/A", // If bio is not available
            smoke: data.profile.is_smoke === 1 ? "Yes" : "No",
            pets: data.profile.is_pets === 1 ? "Yes" : "No",
            cleanliness: data.profile.cleanliness_level || "N/A",
            socialEnvironment: data.profile.social_environment || "N/A",
            sleepSchedule: data.profile.sleep_schedule || "N/A",
            profilePicture: data.profile.profile_picture || "default-profile-pic.png", // Default profile pic if none exists
          });
        } else {
          console.error("Profile not found in the response:", data);
        }
      } catch (error) {
        console.error("Error fetching provider profile:", error);
      }
    };

    fetchProviderProfile();
  }, [userId]); // Dependency array ensures it refetches when the userId changes

  return (
    <div className="dashboard-container">
      <Sidebar userType="finder" />
      <div className="main-content-area">
        <Header />
        <div className="header-bar">
          <button className="back-button" onClick={redirectToDashboard}>
            Back
          </button>
        </div>
        <div className="provider-profile-info">
          <div className="provider-left-section">
            <img
              src={formData.profilePicture}
              alt="Profile pic"
              className="provider-profile-picture"
            />
            <label className="provider-name-label">First Name</label>
            <p className="provider-first-name-p">{formData.firstName}</p>
            <label className="provider-name-label">Last Name</label>
            <p className="provider-last-name-p">{formData.lastName}</p>
          </div>
          <div className="provider-right-section">
            <label className="provider-bio-label">Bio</label>
            <p className="provider-bio-p">{formData.bio}</p>
            <label className="provider-roommate-preference-label">
              Roommate Preferences
            </label>
            <div className="provider-label-container">
              <label htmlFor="smoke" className="provider-smoke-label">
                Smoke-Free:
              </label>
              <p className="provider-smoke-p">{formData.smoke}</p>
              <label htmlFor="pets" className="provider-pets-label">
                Pets:
              </label>
              <p className="provider-pets-p">{formData.pets}</p>
              <label
                htmlFor="cleanliness"
                className="provider-cleanliness-label"
              >
                Cleanliness:
              </label>
              <p className="provider-cleanliness-p">{formData.cleanliness}</p>
              <label
                htmlFor="social-environment"
                className="provider-social-environment-label"
              >
                Social Environment:
              </label>
              <p className="provider-social-environment-p">
                {formData.socialEnvironment}
              </p>
              <label
                htmlFor="sleep-schedule"
                className="provider-sleep-schedule-label"
              >
                Sleep Schedule:
              </label>
              <p className="provider-sleep-schedule-p">
                {formData.sleepSchedule}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
