import React, { useState } from "react";

function ThreeDotMenu({ onDeleteChatHistory }) {
  const [showMenu, setShowMenu] = useState(false);

  const handleMenuToggle = () => {
    setShowMenu((prev) => !prev);
  };

  const handleDeleteChatHistory = () => {
    // Call the passed-in function to delete chat history
    if (onDeleteChatHistory) onDeleteChatHistory();
    setShowMenu(false);
  };

  return (
    <div style={{ position: "relative", display: "inline-block" }}>
      <button 
        onClick={handleMenuToggle} 
        style={{
          border: "none", 
          background: "none", 
          cursor: "pointer", 
          fontSize: "20px", 
          lineHeight: "1"
        }}
        title="Menu"
        aria-label="Menu"
      >
        {/* Three vertical dots (kebab menu)*/}
        &#8942; {/*HTML entity that represents the vertical ellipsis (⋮) symbol*/}
      </button>
      {showMenu && (
        <div 
          style={{
            position: "absolute",
            top: "100%",
            right: 0,
            background: "#fff",
            border: "1px solid #ccc",
            borderRadius: "4px",
            boxShadow: "0 2px 5px rgba(0,0,0,0.3)",
            zIndex: 9999,
            minWidth: "150px"
          }}
        >
          <button
            onClick={handleDeleteChatHistory}
            style={{
              width: "100%",
              padding: "10px",
              background: "none",
              border: "none",
              textAlign: "left",
              cursor: "pointer",
              fontSize: "14px"
            }}
          >
            Delete chat history
          </button>
        </div>
      )}
    </div>
  );
}

export default ThreeDotMenu;
