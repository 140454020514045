import React from "react";
import "./dashboardFilter.css";
import { useState } from "react";

const DashboardFilter = ({ onFilterChange }) => {
  const [filterMessage, setfilterMessage] = useState("");
  const showFilterMessage = () => {
    setfilterMessage(
      "The Filter button will filter the listings based on the selected options."
    );
    setTimeout(() => {
      setfilterMessage("");
    }, 3000);
  };
  return (
    <div className="filter-header">
      <div className="filter-options">
        <select
          id="cost-filter"
          onChange={(e) => onFilterChange("cost", e.target.value)}
          className="filter-select"
        >
          <option value="">Cost</option>
          <option value="low">Low to High</option>
          <option value="high">High to Low</option>
        </select>


        <input
          type="text"
          id="search-query-filter"
          placeholder="Search with (City or City, State)"
          onChange={(e) => onFilterChange("search_query", e.target.value)} 
          className="filter-input"
        />

        <select
          id="wifi-filter"
          onChange={(e) => onFilterChange("is_wifi", e.target.value)}
          className="filter-select"
        >
          <option value="">WIFI</option>
          <option value="true">Yes</option>
          <option value="false">No</option>
        </select>
        <select
          id="parking-filter"
          onChange={(e) => onFilterChange("is_parking", e.target.value)}
          className="filter-select"
        >
          <option value="">Parking</option>
          <option value="true">Yes</option>
          <option value="false">No</option>
        </select>
        <select
          id="laundry-filter"
          onChange={(e) => onFilterChange("is_laundry", e.target.value)}
          className="filter-select"
        >
          <option value="">Laundry</option>
          <option value="true">Yes</option>
          <option value="false">No</option>
        </select>

        <select
          id="accessible-filter"
          onChange={(e) => onFilterChange("is_accessible", e.target.value)}
          className="filter-select"
        >
          <option value="">Accessible</option>
          <option value="true">Yes</option>
          <option value="false">No</option>
        </select>
      </div>
    </div>
  );
};

export default DashboardFilter;
