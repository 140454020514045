import "./quizpage.css";
import Question from "../components/Question.jsx";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

export default function QuizPage() {
  // Retrieve userId from sessionStorage instead of useParams
  const userId = sessionStorage.getItem('userId');
  const [formData, setFormData] = useState({
    smoke: "No",
    pets: "No",
    cleanliness: "Very Clean",
    socialEnvironment: "Quiet",
    sleepSchedule: "Early Bird",
  });

  const [profile, setProfile] = useState(null); 
  const navigate = useNavigate();

  useEffect(() => {
    if (!userId) {
      // If userId is not in sessionStorage, redirect to login page
      navigate('/login');
      return;
    }

    // Fetch the user profile data from the server
    const fetchProfile = async () => {
      try {
        const response = await fetch(`https://roomiefinder.hopto.org:5000/profile/${userId}`);
        const data = await response.json();

        console.log("Profile fetch response:", data);  

        if (data.message === "Profile fetched successfully") {
          const profile = data.profile;
          setProfile(profile);
          setFormData({
            smoke: profile.is_smoke === 1 ? "Yes" : "No",
            pets: profile.is_pets === 1 ? "Yes" : "No",
            cleanliness: profile.cleanliness_level || "Very Clean",
            socialEnvironment: profile.social_environment || "Quiet",
            sleepSchedule: profile.sleep_schedule || "Early Bird",
          });
        } else {
          console.error("Failed to fetch profile:", data.message); 
        }
      } catch (error) {
        console.error("Error fetching profile:", error);
      }
    };

    fetchProfile();
  }, [userId, navigate]);

  function handleInputChange(event) {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
    console.log("Form data updated:", formData);  // Log form data on input change
  }

  async function handleSubmit(event) {
    event.preventDefault();

    if (!profile) {
      console.error("Profile data not loaded.");
      return;
    }

    const formattedDateOfBirth = profile.date_of_birth
      ? new Date(profile.date_of_birth).toISOString().split('T')[0]
      : null;

    // Map the form data and profile data to match the required fields
    const profileData = {
      userId: profile.user_id,  // Using the actual user_id from fetched profile
      firstName: profile.first_name,  // Ensure this is correctly populated
      lastName: profile.last_name,  // Ensure this is correctly populated
      bio: profile.bio || null,  // Bio might be null if not provided
      phoneNumber: profile.phone_number,  // Ensure this is correctly populated
      email: profile.email,  // Ensure this is correctly populated
      smoke: formData.smoke,  // From form input
      pets: formData.pets,  // From form input
      cleanliness: formData.cleanliness,  // From form input
      socialEnvironment: formData.socialEnvironment,  // From form input
      sleepSchedule: formData.sleepSchedule,  // From form input
      gender: profile.gender,  // Using the actual gender from fetched profile
      dateOfBirth: formattedDateOfBirth,  // Ensure the date is properly formatted
      profilePicture: profile.profile_picture,  // Using the actual profile_picture URL
    };

    console.log("Profile Data to Update:", profileData);  // Log data to verify

    try {
      const response = await fetch(`https://roomiefinder.hopto.org:5000/profile/${userId}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(profileData),
      });

      const responseBody = await response.text();  // Get the response body as text
      console.log("Raw Response Body:", responseBody);

      try {
        const responseData = JSON.parse(responseBody);  // Parse the response data
        console.log("Response Data:", responseData);

        if (response.ok) {
          navigate(`/login`);  // Redirect to login after successful update
        } else {
          console.error("Failed to update profile:", responseData);
        }
      } catch (jsonError) {
        console.error("Failed to parse response as JSON:", jsonError);
        console.error("Response body:", responseBody);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  }

  if (!profile) {
    return <div>Loading...</div>;  // Show loading message until profile data is fetched
  }

  return (
    <div className="quiz-page">
      <div className="quiz-content-container">
        <div className="quiz-title">
          <h1>Roommate Preference Quiz</h1>
        </div>
        <div className="quiz-info">
          <form className="question-container" onSubmit={handleSubmit}>
            <Question
              label="Do you prefer a smoke-free household?"
              name="smoke"
              value={formData.smoke}
              options={["No", "Yes"]}
              onChange={handleInputChange}
            />
            <Question
              label="Do you prefer living with pets?"
              name="pets"
              value={formData.pets}
              options={["No", "Yes"]}
              onChange={handleInputChange}
            />
            <Question
              label="What is your preferred cleanliness level?"
              name="cleanliness"
              value={formData.cleanliness}
              options={["Very Clean", "Clean", "Moderate", "Messy", "Very Messy"]}
              onChange={handleInputChange}
            />
            <Question
              label="What is your preferred social environment?"
              name="socialEnvironment"
              value={formData.socialEnvironment}
              options={["Quiet", "Moderate", "Lively", "Party Animal", "Very Social"]}
              onChange={handleInputChange}
            />
            <Question
              label="What is your preferred sleep schedule?"
              name="sleepSchedule"
              value={formData.sleepSchedule}
              options={["Early Bird", "Night Owl", "Flexible", "Mixed Schedule"]}
              onChange={handleInputChange}
            />
            <button type="submit" className="quiz-submit-btn">
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}